import React from 'react'
import banner from './image/our-leadership-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import dots from "./image/icons.webp";
import leadership01 from './image/S-Gurdeep-Singh.webp';
import leadership02 from './image/Arshdeep-Singh-Mundi.webp'
import leadership03 from './image/Prem-Ojha.webp'
import leadership05 from './image/Manjot-Singh-Mann.webp'
import leadership06 from './image/Angad-Deep-Singh.webp'
import leadership07 from './image/S-Sarabjit-Singh.webp'
import leadership08 from './image/Rajesh-Arora.webp'
import leadership09 from './image/Jagjit-singh-2.webp'
import leadership10 from './image/Jagjit-Singh.webp'
import leadership11 from './image/harleen-kaur.webp'
import leadership12 from './image/Rajdeep-Singh-Benipal.webp'
import leadership13 from './image/jagdeep-singh-sandhu.webp'
import leadership20 from './image/Sanjay-Goyal.png';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
export const Leadership = () => {
    return (
    <>
    <Helmet>
    <title>Jujhar Group Leaders - S GURDEEP SINGH, ARSHDEEP SINGH MUNDI</title>
      <meta name="description" content="Jujhar Group Leaders Name are S GURDEEP SINGH, ARSHDEEP SINGH MUNDI, PREM OJHA, S. SARABJIT SINGH, MANJOT SINGH MANN, ANGAD DEEP SINGH, RAJESH ARORA, JAGJIT SINGH, JAGJIT SINGH"/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2 ><span>Making people smile.<em> Since 1990.</em></span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section id="leadership">
            <div className='leadership position-relative'>
            <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                <div className='container'>
                    <div className='web-container'>
                    <div className='row'>
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                            <h1>Our Leadership</h1>
                        </div>
                            <div className='leaderships'>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership01}  alt='S GURDEEP SINGH' title='S GURDEEP SINGH' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>S GURDEEP SINGH</h2>
                                        <ul>
                                            <li>FOUNDER AND CHAIRMAN</li>
                                            <li>JUJHAR GROUP</li>
                                        </ul>                            
                                        <NavLink className="btn-3" to='/leadership/s-gurdeep-singh'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership02}  alt='ARSHDEEP SINGH MUNDI' title='ARSHDEEP SINGH MUNDI' width='250' height='250' />
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>ARSHDEEP SINGH MUNDI</h2>
                                        <ul>
                                            <li>EXECUTIVE DIRECTOR</li>
                                            <li>JUJHAR GROUP</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/arshdeep-singh-mundi'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership05}  alt='MANJOT SINGH MANN' title='MANJOT SINGH MANN' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>MANJOT SINGH MANN</h2>
                                        <ul>
                                            <li>DIRECTOR</li>
                                            <li>G.S. Majestic</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/manjot-singh-mann'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership11}  alt='HARLEEN KAUR' title='HARLEEN KAUR' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>HARLEEN KAUR</h2>
                                        <ul>
                                            <li>PRESIDENT - FINANCE</li>
                                            <li>NETPLUS AND FASTWAY Transmissions</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/harleen-kaur'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership03}  alt='PREM OJHA' title='PREM OJHA' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Dr. PREM OJHA</h2>
                                        <ul>
                                            <li>CEO</li>
                                            <li>NETPLUS</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/prem-ojha'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership07}  alt='S. SARABJIT SINGH' title='S. SARABJIT SINGH' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>S. SARABJIT SINGH</h2>
                                        <ul>
                                            <li>DIRECTOR</li>
                                            <li>ALPHA CITY, GRAND WALK & BPL MEDIA</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/s-sarabjit-singh'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership06}  alt='ANGAD DEEP SINGH' title='ANGAD DEEP SINGH' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>ANGAD DEEP SINGH</h2>
                                        <ul>
                                            <li>MANAGING DIRECTOR</li>
                                            <li>BPL MEDIA</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/angad-deep-singh'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                {/* <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership04}  alt='GURINDER BHATTI' />
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>GURINDER BHATTI</h2>
                                        <ul>
                                            <li>CHAIRMAN & MANAGING DIRECTOR</li>
                                            <li>ESS GLOBAL</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/gurinder-bhatti'><span> Read Bio</span></NavLink>
                                    </div>
                                </div> */}
                                
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership08}  alt='Rajesh Arora' title='Rajesh Arora' width='250' height='250' />
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Rajesh Arora</h2>
                                        <ul>
                                            <li>DIRECTOR</li>
                                            <li>ALPHA CITY</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/rajesh-arora'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership09}  alt='Jagjit Singh' title='Jagjit Singh' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Jagjit Singh</h2>
                                        <ul>
                                            <li>DIRECTOR</li>
                                            <li>ALPHA CITY</li>
                                        </ul>
                                        {/* <NavLink className="btn-3" to='/rajesh-arora'><span> Read Bio</span></NavLink> */}
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership10}  alt='Jagjit Singh' title='Jagjit Singh' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Jagjit Singh</h2>
                                        <ul>
                                            <li>DIRECTOR</li>
                                            <li>JUJHAR CONSTRUCTIONS & TRAVELS</li>
                                        </ul>
                                        {/* <NavLink className="btn-3" to='/rajesh-arora'><span> Read Bio</span></NavLink> */}
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership13}  alt='Jagjit Singh' title='Jagjit Singh' width='250' height='250'/>
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Jagdeep Singh Sandhu</h2>
                                        <ul>
                                            <li>CEO & Group Editor</li>
                                            <li>Daily Post</li>
                                        </ul>
                                        {/* <NavLink className="btn-3" to='/rajesh-arora'><span> Read Bio</span></NavLink> */}
                                    </div>
                                </div>
                                <div className='leadership2'>
                                    <div className='leadershipimg'>
                                    <img src={leadership20}  alt='Mr. Sanjay Goyal' title='Mr. Sanjay Goyal' width='250' height='250' />
                                    </div>
                                    <div className='leadershipcont'>
                                        <h2>Mr. Sanjay Goyal</h2>
                                        <ul>
                                            <li>Group CFO and Head (System & Process)</li>
                                        </ul>
                                        <NavLink className="btn-3" to='/leadership/sanjay-goyal'><span> Read Bio</span></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </>
  )
}
