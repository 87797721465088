import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import leadership01 from './image/S-Sarabjit-Singh.webp';
import { Helmet } from 'react-helmet';

export const Leadershipdetails7 = () => {
  return (
    <>
        <Navbar />
        <Helmet>
    <title>S. SARABJIT SINGH | Director Alpha City, Grand Walk and BPL Media</title>
    <meta name="description" content=""/>
    </Helmet>
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>S. Sarabjit Singh</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>S. Sarabjit Singh</h1>
                                <h2><span>Director</span> <span>Alpha City, Grand Walk and BPL Media</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='S. Sarabjit Singh' title='S. Sarabjit Singh' width='200' height='200'/>
                                <p>One of Punjab’s most elite and successful businessmen, Mr. S Sarabjit Singh has led several profitable businesses ranging from a variety of sectors such as residential plots, residential flats, amusement parks, hotels, malls and gyms to cable network distribution and TV channels. The crown jewel among them all is the Alpha International City, a world-class, sprawling township spread across 350 acres in the holy city of Amritsar.</p>
<p>Under his guidance, Fastway has managed to expand its distribution network across Amritsar, Jalandhar, Phagwara and several other areas.</p> 
<p>The cable television business, as a result of his expertise, has grown rapidly over the past few years from a small venture to an extensive network covering the entire regions of Punjab, Himachal Pradesh, New Delhi, Haryana and Rajasthan. Such growth has been fueled by his keen ability to efficiently network with broadcasters as well as some of the top notch companies in the industry.</p>
<p>Mr. S Sarabjit Singh currently holds directorship on the board of 16 companies.</p>

                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
