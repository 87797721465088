import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/harleen-kaur.webp';

export const Leadershipdetails9 = () => {
  return (
    <>
    <Helmet>
    <title>HARLEEN KAUR | President - Finance Netplus and Fastway Transmissions</title>
      <meta name="description" content="Harleen Kaur excels in the field of finance and entrepreneurship. Having completed her B.Com (Hons) from the prestigious Shri Ram College of Commerce and her MBA in Finance and Marketing from Delhi."/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Harleen Kaur</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Harleen Kaur</h1>
                                <h2><span>President - Finance</span> <span>Netplus and Fastway Transmissions</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Harleen Kaur' title='Harleen Kaur' width='200' height='200'/>
                                <p>As confident and determined as they come, Harleen Kaur excels in the field of finance and entrepreneurship. Having completed her B.Com (Hons) from the prestigious Shri Ram College of Commerce and her MBA in Finance and Marketing from Delhi, she is responsible for managing the financial and allied business operations of Fastway Transmissions and Netplus Broadband.</p>
                                <p>Harleen is as much a businesswoman as she is an avid humanitarian, spearheading the Jujhar Group’s CSR initiatives for the betterment of the under-privileged and less-fortunate. She is also an active member of an NGO that focuses on spreading awareness about human rights and environmental concerns, in order to build a better, more sustainable world for current and future generations.</p>
                                <p>In her spare time, Harleen loves to travel and explore the world, with her favourite destination being Europe. She is also passionate about reading, and loves to kick back and relax with a good book from time to time.</p>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
