import React from "react";
import Home from "./Home";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Error from "./Error";
import '../src/stylesheet.css';
import '../src/font/stylesheet.css';
import ScrollToTop from "./ScrollToTop";
import { About_us } from "./About_us";
import { Our_business } from "./Our_business";
import { Leadership } from "./Leadership";
import { Csr } from "./Csr";
import { Entertainment } from "./Entertainment";
import { Career } from "./Career";
import { Business2 } from "./Business2";
import { Logistics } from "./Logistic";
import { Infrastructure } from "./Infrastructure";
import { Media } from "./Media";
import { LivingIndia} from "./livingindia";
import { Edutech } from "./Edutech";
import { Realstate } from "./Realstate";
import { Hospitality } from "./Hospitality";
import { Satellite } from "./Satellite";
import Number from "./Number";
import { Leadershipdetails } from "./Leadershipdetails";
import { Contact_us } from "./Contact-us";
import { Leadershipdetails2 } from "./Leadershipdetails2";
import { Leadershipdetails3 } from "./Leadershipdetails3";
import { Leadershipdetails4 } from "./Leadershipdetails4";
import { Leadershipdetails5 } from "./Leadershipdetails5";
import { Leadershipdetails6 } from "./Leadershipdetails6";
import { Leadershipdetails7 } from "./Leadershipdetails7";
import { Leadershipdetails8 } from "./Leadershipdetails8";
import { Leadershipdetails9 } from "./Leadershipdetails9";
import { Leadershipdetails10 } from "./Leadershipdetails10";
import { Sitemap } from "./Sitemap";
import { Disclaimer } from "./Disclaimer";
import { Privacy_policy } from "./Privacy-policy";
import { Healthcare } from "./Healthcare";

const App = () => {
  return (
    <>
    <Router>
    <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/about-jujhar-group" component={About_us}></Route>
        <Route exact path="/businesses" component={Our_business}></Route>
        <Route exact path="/leadership" component={Leadership}></Route>
        <Route exact path="/csr" component={Csr}></Route>
        <Route exact path="/businesses/entertainment" component={Entertainment}></Route>
        <Route exact path="/careers" component={Career}></Route>
        <Route exact path="/businesses/broadband" component={Business2}></Route>
        <Route exact path="/businesses/logistics" component={Logistics}></Route>
        <Route exact path="/businesses/infrastructure" component={Infrastructure}></Route>
        <Route exact path="/businesses/digital-media" component={Media}></Route>
        <Route exact path="/businesses/media" component={LivingIndia}></Route>
        <Route exact path="/businesses/edutech" component={Edutech}></Route>
        <Route exact path="/businesses/real-estate" component={Realstate}></Route>
        <Route exact path="/businesses/hospitality" component={Hospitality}></Route>
        <Route exact path="/businesses/healthcare" component={Healthcare}></Route>
        <Route exact path="/setellite-new-channel" component={Satellite}></Route>
        <Route exact path="/numbers" component={Number}></Route>
        <Route exact path="/leadership/s-gurdeep-singh" component={Leadershipdetails}></Route>
        <Route exact path="/leadership/arshdeep-singh-mundi" component={Leadershipdetails2}></Route>
        <Route exact path="/leadership/prem-ojha" component={Leadershipdetails3}></Route>
        <Route exact path="/leadership/gurinder-bhatti" component={Leadershipdetails4}></Route>
        <Route exact path="/leadership/manjot-singh-mann" component={Leadershipdetails5}></Route>
        <Route exact path="/leadership/angad-deep-singh" component={Leadershipdetails6}></Route>
        <Route exact path="/leadership/s-sarabjit-singh" component={Leadershipdetails7}></Route>
        <Route exact path="/leadership/rajesh-arora" component={Leadershipdetails8}></Route>
        <Route exact path="/leadership/harleen-kaur" component={Leadershipdetails9}></Route>
        <Route exact path="/leadership/sanjay-goyal" component={Leadershipdetails10}></Route>
        <Route exact path="/contact-us" component={Contact_us}></Route>
        <Route exact path="/sitemap" component={Sitemap}></Route>        
        <Route exact path="/disclaimer" component={Disclaimer}></Route>        
        <Route exact path="/privacy-policy" component={Privacy_policy}></Route>        
        <Route component={Error}></Route>
      </Switch>
      </Router>
    </>
  );
};

export default App;