import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import leadership01 from './image/Manjot-Singh-Mann.webp';
import { Helmet } from 'react-helmet';

export const Leadershipdetails5 = () => {
  return (
    <>
        <Navbar />
        <Helmet>
    <title>MANJOT SINGH MANN | Director Jujhar Group</title>
    <meta name="description" content=""/>
    </Helmet>
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Manjot Singh Mann</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Manjot Singh Mann</h1>
                                <h2><span>Director</span> <span>Jujhar Group</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Manjot Singh Mann' title='Manjot Singh Mann' width='200' height='200'/>
                                <p>A civil engineer by profession, Mr. Manjot Singh Mann joined the Jujhar Group back in 2015, and has since gone on to become a board member for 20 group companies.</p>
<p>Mr. Manjot Singh Mann boasts an outstanding academic record, with an undergraduate degree in Civil Engineering from the Thapar Institute of Engineering and Technology, a postgraduate degree from the National Institute of Construction Management & Research (NICMAR) and an MBA from the elite Indian School of Business.</p>
<p>With a demonstrated history of success in the commercial real-estate sector, he possesses an in-depth knowledge of the latest innovations and developments taking place in the world of civil engineering and construction. His true expertise thus lies in the management of real estate, construction and infrastructure companies and projects belonging to the group. Under his leadership, the renowned Grand Walk Mall was constructed in Ludhiana, complete with world class amenities, an ultra-modern aesthetic design, and housing major international high-street brands and restaurants. A connoisseur of better living, he is also responsible for guiding Venus County Developers Private Limited, a real estate development company under the Jujhar Group, towards building a world-class habitat infrastructure at par with the highest international standards.</p>

                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
