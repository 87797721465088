import React, { useState, useEffect } from 'react'
import banner from './image/business-banner-new.png';
import banner2 from './image/business-banner-mobile-new.png';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import dots from "./image/icons.webp";
import busi_img from './image/project-banner.jpg';
import busi_img3 from './image/netplus_banner.jpg';
import busi_img2 from './image/fastway_banner.jpg';
import busi_img4 from './image/media-banner.jpg';
import busi_img4b from './image/mmedia-banner.jpg';
import busi_img5 from './image/realestate_banner.jpg';
import busi_img7 from './image/infra-banner.jpg';
import busi_img8 from './image/hospitality-banner.jpg';
import busi_img9 from './image/daily_post.jpg';
import busi_img13 from './image/Healthcare.webp';
import proj_logo from './image/jujhar-white.webp';
import proj_logo2 from './image/Fastway.webp';
import proj_logo3 from './image/Netplus_Broadband.webp';
import proj_logo4 from './image/Daily_Post_Punjabi_white.webp';
import proj_logo5 from './image/jujhar_realty.webp';
import proj_logo7 from './image/Sky_Ama_Infra-white.webp';
import proj_logo8 from './image/jujhar_marriot_logo_white.webp';
import proj_logo9 from './image/daily-post-logo.png';
import proj_logo10 from './image/Grand_Walk_Mall_white.webp';
import proj_logo11 from './image/Alpha_W.webp';
import proj_logo12 from './image/punjabi_hits_internal.webp';
import proj_logo13 from './image/jujhar-healthcare-white.webp'
import proj_logo14 from './image/apollo.webp'
import proj_logo15 from './image/js-enclave-logo.png'
import proj_logo16 from './image/green-city-residency.png'
import proj_logo17 from './image/grandville.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function BusinessData({ item, onClick, selected }) {
return (
<div className={selected ? "business active" : "business" } onClick={onClick}>
    {item.data}
</div>
);
}

export const Our_business = () => {
    document.title="Jujhar Group Businesses Namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure and Hospitality."
const [items] = useState(() => [
{ id: 1, data: <div id='firstDiv'>
    <h3>Logistics</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img} alt='Logistics' title='Logistics' width='1016' height='500' /> </div>
        <div className='businesscont'>
            <span className='busicon'></span>
            <Link to='/businesses/logistics'>
            <div className='businesslogo'> <img src={proj_logo} alt='Jujhar Construction & Travels' title='Jujhar Construction & Travels' width='200' height='200' /> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4>Founded in<span>1996</span></h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>132 + Buses</span> Volvo, Mercedes and Isuzu</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>375+</span> Car Carriers</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Ranked No. 1 </span> in Car Transportation Segment</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'><span>Know More</span></div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 2, data: <div>
    <h3> Entertainment </h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img2} alt='Entertainment' title='Entertainment' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
            <Link to='/businesses/entertainment'>
            <div className='businesslogo'> <img src={proj_logo2} alt="Fastway Transmissions" title='Fastway Transmissions' width='200' height='200' /> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>5 Million+</span> Subscribers</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>2.5 Crore</span> Viewers</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>8000+</span> Channel Partners</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>95%</span> Market Share</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'> <span>Know More</span> </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 3, data: <div>
    <h3>Broadband </h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img3}  alt='Broadband' title='Broadband' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
            <Link to='/businesses/broadband'>
            <div className='businesslogo'> <img src={proj_logo3} alt="Netplus Broadband" title='Netplus Broadband' width='200' height='200' /> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>6 Lakh+</span> Subscriber Base</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>2000+ premium</span> Enterprise customers</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>6 Million+</span> Home Passes on FTTH</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4>Service in<span>300+ Cities</span></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'> <span>Know More</span></div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 4, data: <div>
    <h3>Digital Media</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img9} alt='Digital Media' title='Digital Media' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
            <Link to='/businesses/digital-media'>
            <div className='businesslogo'> <img src={proj_logo4} alt="Daily Post" title='Daily Post' width='200' height='200'/> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>2 million +</span> FACEBOOK FOLLOWERS</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>1.5 million +</span> SUBSCRIBERS ON YOUTUBE</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Ranked no.1</span> Web Channel in Punjab</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'> <span>Know More</span> </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 8, data: <div>
    <h3>Media</h3>
    <div className='businessview position-relative media'>
        <div className='businessbanner'> <img src={busi_img4} alt='Media' title='Media' width='1016' height='500' className='desktop-show'/> <img src={busi_img4b} alt='Media' title='Media' width='1016' height='500' className='mobile-show'/></div>
        <div className='businesscont'>
        <span className='busicon'></span>
        <Link to='/businesses/media'>
            <div className='businesslogo justify-space-between'>
                <img src={proj_logo9} alt="Daily Post" title='Daily Post' width='200' style={{objectFit: "contain", width: '21%'}} />
                <img src={proj_logo12} alt="Punjabi Hits" class="margin10" title='Punjabi Hits' width='200' height='200'/>
                </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Ranked no.1</span> satellite news channel in Punjab</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4>Fast Emerging  <span>Regional Music Channel</span> </h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Rated 10.3</span> by BARC</h4>
                        </div>
                    </div>
                   
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Extensive collection</span> of Latest and Evergreen Punjabi tracks 

</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'>
                   <span>Know More</span>
                </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 5, data: <div>
    <h3>Real Estate</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img5} alt='Real Estate' title='Real Estate' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
        <Link to='/businesses/real-estate'>
            <div className='businesslogo multiLogo'> 
            <img src={proj_logo5} alt="Jujhar Realty" title='Jujhar Realty' width='200' height='200'/>
            <div className='real-est-ex-logo'>
                <img src={proj_logo10} alt="Grand Walk" class="addMore" title='Grand Walk' width='200' height='200'/>
            <img src={proj_logo11} alt="Alpha International City" class="addMore" title='Alpha International City' width='200' height='200'/>
            <img src={proj_logo15} alt="jsENCLAVE" class="addMore" title='jsENCLAVE' width='200' height='200'/>
            <img src={proj_logo16} alt="Green City Residency" class="addMore" title='Green City Residency' width='200' height='200'/>
            <img src={proj_logo17} alt="GrandVille" class="addMore" title='GrandVille' width='200' height='200'/>
            
            </div>
            </div>
            <div className='businessbody top65'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Residential</span></h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Commercial</span></h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Work spaces</span></h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>High Street Malls</span></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'>
                    <span>Know More</span>
                </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 7, data: <div>
    <h3>Infrastructure</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img7} alt='Infrastructure' title='Infrastructure' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
        <Link to='/businesses/infrastructure'>
            <div className='businesslogo'> <img src={proj_logo7} alt="Sky Ama Infra" title='Sky Ama Infra' width='200' height='200'/> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                    <div className='busilabel'>
                    <h4>Specialization in execution of<span>large scale fiber laying projects</span></h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                    <div className='busilabel'>
                    <h4>Launched <span>Wi-Fi services at the Golden Temple</span> in association with Amritsar Smart City</h4>
                    
                    </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'>
                    <span>Know More</span>
                </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 8, data: <div>
    <h3>Hospitality</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img8} alt='Hospitality' title='Hospitality' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
        <Link to='/businesses/hospitality'>
            <div className='businesslogo'> <img src={proj_logo8 }alt="Fairfield by Marriott" title='Fairfield by Marriott' width='200' height='200'/> </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>4-Star property</span> located in the holy city of Amritsar</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>130+ Rooms</span> and world class amenities</h4>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>Award Winning</span> Customer Service</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'>
                   <span>Know More</span>
                </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> },
{ id: 10, data: <div>
    <h3>Healthcare</h3>
    <div className='businessview position-relative'>
        <div className='businessbanner'> <img src={busi_img13} alt='Healthcare' title='Healthcare' width='1016' height='500' /> </div>
        <div className='businesscont'>
        <span className='busicon'></span>
        <Link to='/businesses/healthcare'>
            <div className='businesslogo multiLogo'> 
            <img src={proj_logo13}alt="Jujhar Healthcare" title='Jujhar Healthcare' width='200' height='200'/>
            <div>
                <img src={proj_logo14} alt="Apollo" class="addMore" title='Apollo' width='200' height='200'/>
            </div>
            </div>
            <div className='businessbody'>
                <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                        <div className='busilabel'>
                            <h4><span>World-class healthcare, now in Punjab</span>Jujhar Group has ventured into the healthcare industry via its partnership with Apollo Health & Lifestyle Limited.	</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='businessfooter '>
                <div className='click'>
                   <span>Know More</span>
                </div>
            </div>
            </Link>
            <span className='busicon'></span>
        </div>
    </div>
</div> }

]);
const [selectedIndex, setSelectedIndex] = useState(-1);
useEffect(() => {

window.addEventListener('scroll', document.getElementById('firstDiv').click());


}, []);



return (
<>
<Helmet>
    <title>Jujhar Group Businesses Namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure and Hospitality.</title>
      <meta name="description" content="Jujhar Group Businesses Namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure and Hospitality."/>
    </Helmet>
    <Navbar />
    <section id='banner' className='p-0 business-banner'>
        <div className='banner desktop-show our-business' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2><span>High quality products. <em> Unparalleled service.</em></span></h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='banner mobile-show' style={{ backgroundImage: `url(${banner2})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2><span>High quality products. <em> Unparalleled service.</em></span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='our-business'>
        <div className='our-business position-relative'>
            <div className='container'>
                <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                    <h1>Our Businesses</h1>
                </div>
                <div className='col-12 jseffect'>
                    <div className='businessbox d-flex position-relative' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">

                        {items.map((item, index) => (
                        <BusinessData key={item.id} item={item} onClick={()=> setSelectedIndex(index)}
                            selected={index === selectedIndex}
                            />
                            ))}

                    </div>
                </div>
            </div>
            <div className="dot-img position-absolute top-0">
                <img src={dots} alt="" className='slow-move-anticlockwise' />
            </div>
        </div>
    </section>
    <Footer />
</>
)
}