import React, {useState} from 'react'
import banner from './image/daily-post-new.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Daily_Post_Punjabi.webp';
import icon1 from './image/television.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot-media.webp';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export const Media = () => {
    const [state, setState] = useState({var1:1, var2:1});
  return (
    <>
    <Helmet>
    <title>Vigilant Media - Daily Post - Jujhar Group Ludhiana Punjab</title>
      <meta name="description" content="Jujhar Group - Daily post is the new age web media platform boasting over 2 million subscribers across popular social media channels."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner media' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Your Pioneer Regional <em>News Channel</em> </span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>VIGILANT MEDIA – DAILY POST</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />           
                    </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} title='Daily Post' alt="Daily Post" width='200' height='200' />
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Vigilant Media – Daily Post </h1>
                                            <p>Daily post is the new age web media platform boasting over 2 million subscribers across popular social media channels.  With its motto being the  'Journalism of Hope', it covers all news updates and stories across Punjab along multiple spheres such as politics, religion, entertainment and sports.</p>
                                            <p>It is one of the most trusted, liked and followed news and media channels for a vast majority of Punjabi viewers, and has a significant following and viewership on Facebook and YouTube.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute'>
        <img src={dot_left} alt='' className='slow-move-anticlockwise'/>
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="News and Media Channel" title="News and Media Channel" width="70" height="70"/>
                                    <span>News and Media Channel </span>
                                </div>                              
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1===1) ? 1 : 2} end={2} duration={1} onEnd={()=> setState({var1:2})}/> : <CountUp start={1} end={2} duration={0} />}
             <p>million</p>+   
        
        <div className='labelcont'>
          <em>Facebook followers</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {/* {isVisible ? <CountUp start={(state.var1===1) ? 1 : 1} end={1} duration={1}  onEnd={()=> setState({var2:1})}/> : <CountUp start={1} end={1} duration={0} />} */}
             {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 1} end={1.5} duration={2} separator=" " decimals={1}  decimal="."  onEnd={()=> setState({ workforce:1.5 })} /> : <CountUp start={1} end={1.5} duration={0} />}            
             <p>million</p>+           
            <div className='labelcont'>
             <em>subscribers on YouTube</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div> 
           
      </div>
      <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                <li>Ranked No.1 <span>Web Channel in Punjab</span></li>
                                <li>Subscribers across <span>the globe of Indian diaspora</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://dailypost.in/" class="btn-3" target='_blank' rel="noreferrer"><span>www.dailypost.in</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
