import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import leadership01 from './image/Angad-Deep-Singh.webp';
import { Helmet } from 'react-helmet';

export const Leadershipdetails6 = () => {
  return (
    <>
        <Navbar />
        <Helmet>
    <title>ANGAD DEEP SINGH | Managing Director BPL Media</title>
    <meta name="description" content=""/>
    </Helmet>
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Angad Deep Singh</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Angad Deep Singh</h1>
                                <h2><span>Managing Director</span> <span>BPL Media</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Angad Deep Singh' title='Angad Deep Singh' width='200' height='200'/>
                                <p>Mr. Angad Deep Singh is a born entrepreneur and leader, boasting nearly a decade of experience across major aspects of business such as formation, operations, finance and management. Being a visionary and an effective communicator, Mr. Angad Deep Singh is revered for his ability to identify and leverage the key strengths and capabilities of his employees towards the effective attainment of organizational goals.</p>
<p>With a bachelor’s degree in commerce from Punjab University, and having gained experience in the cable industry through his father, a distributor for the Fastway Transmissions cable network since 2004, Mr. Angad Singh joined Jujhar in 2014 as the founder of Living India News, a channel catering to the coverage of important news and events happening across Punjab in regional dialects. At present, he is the managing director of BPL Media, the company behind Living India News as well as another channel by the name Punjabi Hits.</p>
<p>Being a passionate professional with a thriving business attitude, he also ventured into commercial complexes by playing an active role in the development of the Grand Walk Mall in Ludhiana, and has many other projects lined up in the real estate and hotel industries.</p>

                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
