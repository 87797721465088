import React, {useState} from 'react'
import banner from './image/net-plus-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Netplus_Broadband.webp';
import icon1 from './image/net-plus-1.webp';
import icon2 from './image/net-plus-2.webp';
import icon3 from './image/net-plus-3.webp';
import icon4 from './image/net-plus-4.webp';
import icon5 from './image/net-plus-5.webp';
import icon6 from './image/net-plus-6.webp';
import icon7 from './image/net-plus-7.webp';
import logo01 from './image/logo1.webp';
import logo02 from './image/logo2.webp';
import logo03 from './image/logo3.webp';
import logo04 from './image/logo4.webp';
import logo05 from './image/logo5.webp';
import logo06 from './image/logo6.webp';
import logo07 from './image/logo7.webp';
import logo08 from './image/logo8.webp';
import logo09 from './image/logo9.webp';
import logo10 from './image/logo10.webp';
import logo11 from './image/logo11.webp';
import logo12 from './image/logo12.webp';
import logo13 from './image/logo13.webp';
import logo14 from './image/logo14.webp';
import logo15 from './image/logo15.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot-broadband.webp';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom'

export const Business2 = () => {
  const [state, setState] = useState({var1:1, var2:1, var3:1, var4:1, var5:1});
  return (
    <>
      <Helmet>
    <title>Gurdeep Singh Netplus: Driving Connectivity and Innovation at Netplus</title>
      <meta name="description" content="Gurdeep Singh Netplus, is the driving force behind Netplus, a leading provider of cutting-edge connectivity solutions. "/>
    </Helmet>
        <Navbar/>
             <section id='banner' className='p-0 textcenter'>
        <div className='banner broadband' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Next-Gen <em>Speeds and Services</em> </span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </section>
       <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>NETPLUS BROADBAND</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                    <img src={logo1} title='Netplus Broadband' alt="Netplus Broadband" width='200' height='200' />
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                            <h1>NETPLUS BROADBAND</h1>
                                            <p>The preferred broadband service provider of Punjab - revered for its superfast connection speeds and high customer retention rate, Netplus Broadband is the dominant and fastest growing ISP in the region, providing next-gen services such as High Speed Broadband, Smart Telephony & OTT.</p>
                                            <p>The company offers high speed internet through the FTTH network to residential, SME and corporate customers across major cities and townships of urban Punjab, and via hotspot wireless broadband in rural Punjab. With an extensive FTTH network across 8 states, the company delivers world-class services to over 6 lakh customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute slow-move-anticlockwise'>
        <img src={dot_left} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' /> 
    </div>
                        <div className='row'>
                            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} title='FTTH' alt="FTTH" width='70' height='70' />
                                    <p>FTTH</p>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} title='OTT' alt="OTT" width='70' height='70' />
                                    <p>OTT</p>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon3} title='Voice Services' alt="Voice Services" width='70' height='70' />
                                    <p>Voice Services</p>
                                </div>                                
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon4} title='Home Security' alt="Home Security" width='70' height='70' />
                                    <p>Home Security</p>
                                </div>     
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon5} title='Home Automation' alt="Home Automation" width='70' height='70' />
                                    <p>Home Automation</p>
                                </div>    
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon6} title='Enterprise Solutions' alt="Enterprise Solutions" width='70' height='70' />
                                    <p>Enterprise Solutions</p>
                                </div>    
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon7} title='Government Projects' alt="Government Projects" width='70' height='70' />
                                    <p>Government Projects</p>
                                </div>                               
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
            <h2>Snapshot</h2>
        </div>
             </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1===1) ? 1 : 6} end={6} duration={1} onEnd={()=> setState({var1:5})}/> : <CountUp start={1} end={6} duration={0} />}
             <p>lakh</p> +
        
        <div className='labelcont'>
          <em>Subscriber Base</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var2===1) ? 1 : 2000} end={2000} duration={2} onEnd={()=> setState({var2:2000})}/> : <CountUp start={1} end={1} duration={0} />}+   
             <div className='labelcont'>
             <em>premium customers</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
      
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var3===1) ? 1 : 6} end={6} duration={1} onEnd={()=> setState({var3:6})}/> : <CountUp start={1} end={6} duration={0} />} 
             <p>million </p>+
             <div className='labelcont'>
               <em>home passes of FTTH</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var4===1) ? 1 : 300} end={300} duration={1} onEnd={()=> setState({var4:300})}/> : <CountUp start={1} end={300} duration={0}/>}+
             <div className='labelcont'>
          <em>cities</em>
        </div>
            </div>
          )}
        </VisibilitySensor>
      
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var5===1) ? 1 : 8} end={8} duration={1} onEnd={()=> setState({var5:8})}/> : <CountUp start={1} end={8} duration={0}/>}
             <div className='labelcont'>
          <em>states</em>
        </div>
            </div>
          )}
        </VisibilitySensor>
      
      </div>
                              
                            </div>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Triple Play Services<span>Voice + Video+ Data</span></li>
                                    <li>State-Of-The-Art<span>NOC & DWDM network</span></li>
                                    <li>Market leader in SOGA<span>(Share of Gross Ads)</span></li>
                                    <li>Collaboration with Facebook<span>Free Wi-Fi services</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='technology'>
                <div className='technology-partners'>
                    <div className='row'>
                        <div className='container'>
                            <div className='web-container'>
                            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                <h2>Technology Partners</h2>
                            </div>
                            <div className='partners-logo text-center'>
                                <ul data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                    <li><img src={logo01} alt="Nokia" title="Nokia" width="400" height="200" /></li>
                                    <li><img src={logo02} alt="Sterlite-tech" title="Sterlite-tech" width="400" height="200" /></li>
                                    <li><img src={logo03} alt="Ericsson" title="Ericsson" width="400" height="200" /></li>
                                    <li><img src={logo04} alt="Oracle" title="Oracle" width="400" height="200" /></li>
                                    <li><img src={logo05} alt="Vodaphone" title="Vodaphone" width="400" height="200" /></li>
                                    <li><img src={logo06} alt="Relieance" title="Relieance" width="400" height="200" /></li>
                                    <li><img src={logo07} alt="Solarwinds" title="Solarwinds" width="400" height="200" /></li>
                                    <li><img src={logo08} alt="Genexis" title="Genexis" width="400" height="200" /></li>
                                    <li><img src={logo09} alt="Elitescore" title="Elitescore" width="400" height="200" /></li>
                                    <li><img src={logo10} alt="Cisco" title="Cisco" width="400" height="200" /></li>
                                    <li><img src={logo11} alt="Tata" title="Tata" width="400" height="200" /></li>
                                    <li><img src={logo12} alt="Airtel" title="Airtel" width="400" height="200" /></li>
                                    <li><img src={logo13} alt="BSNL" title="BSNL" width="400" height="200" /></li>
                                    <li><img src={logo14} alt="Huawai" title="Huawai" width="400" height="200" /></li>
                                    <li><img src={logo15} alt="ZTE" title="ZTE" width="400" height="200" /></li>
                                </ul>
                            </div>
                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://www.netplus.co.in" class="btn-3" target='_blank' rel="noreferrer"><span>www.netplus.co.in</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
