import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import leadership01 from './image/Rajesh-Arora.webp';
import { Helmet } from 'react-helmet';

export const Leadershipdetails8 = () => {
  return (
    <>
        <Navbar />
        <Helmet>
    <title>RAJESH ARORA | Director Alpha City</title>
      <meta name="description" content=""/>
    </Helmet>
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>RAJESH ARORA</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Rajesh Arora</h1>
                                <h2><span>Director</span> <span>Alpha City</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Rajesh Arora' title='Rajesh Arora' width='200' height='200'/>
                                <p>With over 20 years of experience in the real estate industry, Mr. Rajesh Arora is a consummate professional who has conducted business with major companies such as Morgan Stanley, Alpha G Crop, OMAXE, and almost all big players in Punjab.</p>
                                <p>A man of many talents, he possesses expertise in several important fields such as land acquisitions, government processes and approvals, and heading the CREDAI team in Amritsar.</p>
                                </div>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
