import React, {useState} from 'react'
import banner from './image/Hospitality.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Jujhar_Marriot_Logo.webp';
import dots from "./image/icons.webp";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot-hospitality.webp';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Hospitality = () => {
    const[state, setState] = useState({var1:1, var2:1});
  return (
    <>
     <Helmet>
    <title>Fairfield by Marriott Amritsar - Jujhar Group Punjab</title>
      <meta name="description" content="The Jujhar Group, a Ludhiana-based company with diversified business interests, has forayed into the hospitality sector by acquiring the Fairfield by Marriott Hotel Amritsar."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner hospitality' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                    <h2><span>Creating <em>Memories</em> that Last a Lifetime</span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </section>
            <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>Fairfield by Marriott Amritsar</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />  
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt='Hospitality' title='Hospitality' width='200' height='200'/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>FAIRFIELD BY MARRIOTT AMRITSAR</h1>
                                            <p>Fairfield by Marriott Amritsar is the first property in the hospitality vertical of the Jujhar Group.</p>
                                            <p>The 4-star property, comprising over 130 rooms and complete with world-class amenities, is known for its stellar combination of contemporary comfort and award-winning customer service.</p>
                                            <p>Given the expected high growth of the hospitality sector over the next few years, the Jujhar Group believes that its acquisition of Fairfield by Marriott is the first of many to come.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1===1) ? 1 : 4} end={4} duration={1} onEnd={()=> setState({var1:4})}/> : <CountUp start={1} end={4} duration={0} />}
        <p>star</p>
        <div className='labelcont'>
          <em>property located in the Amritsar</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var2===1) ? 1 : 130} end={130} duration={2} onEnd={()=> setState({var2:130})}/> : <CountUp start={1} end={130} duration={0} />}+      
             <p>rooms</p>          
           <div className='labelcont'>
             <em>& World class amenities</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
                              
                            </div>
                            
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Award-winning  <span>customer service</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://www.marriott.com/hotels/travel/atqfi-fairfield-amritsar/" class="btn-3" target='_blank' rel="noreferrer"><span>www.marriott.com</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
