import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import icon01 from './image/arrow.webp'
import { NavLink } from 'react-router-dom'

export const Sitemap = () => {
  return (
    <>
        <Navbar />
        <section id="stemap">
    <div class="container">
        <div class="web-container">
            <div class="stemap">
                <div class="title">
                    <h2>Sitemap</h2>
                </div>
                <div class="smap">
                    <div class="sitembox">
                        <ul>
                            <li class="stxmn"><NavLink to="/">Home</NavLink></li>
                            <li class="stxmn"><NavLink to="/about-jujhar-group">About Us</NavLink></li>
                        </ul>
                    </div>
                    <div class="sitembox">
                        <ul>
                            <li class="stxmn"><NavLink to="/businesses">Our Businesses</NavLink>
                                <ul class="stxbdr">
                                    <li class="stxmns"><NavLink to='/businesses/logistics'><img src={icon01} />Logistic</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/entertainment"><img src={icon01} />Entertainment</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/broadband"><img src={icon01} />Broadband</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/digital-media"><img src={icon01} />Digital Media</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/media"><img src={icon01} />Media</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/real-estate"><img src={icon01} />Real Estate</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/infrastructure"><img src={icon01} />Infrastructure</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/hospitality"><img src={icon01} />Hospitality</NavLink></li>
                                    <li class="stxmns"><NavLink to="/businesses/healthcare"><img src={icon01} />Healthcare</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="sitembox">
                        <ul>
                            <li class="stxmn"><NavLink to="/leadership">Our Leadership</NavLink>
                                <ul class="stxbdr">
                                    <li class="stxmns"><NavLink to="/leadership/s-gurdeep-singh"><img src={icon01} />S Gurdeep Singh</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/arshdeep-singh-mundi"><img src={icon01} />Arshdeep Singh Mundi</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/manjot-singh-mann"><img src={icon01} />Manjot Singh Mann</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/manjot-singh-mann"><img src={icon01} />Harleen Kaur</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/prem-ojha"><img src={icon01} />Dr. Prem Ojha</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/s-sarabjit-singh"><img src={icon01} />S. Sarabjit Singh</NavLink></li>    
                                    <li class="stxmns"><NavLink to="/leadership/angad-deep-singh"><img src={icon01} />Angad Deep Singh</NavLink></li>
                                    <li class="stxmns"><NavLink to="/leadership/rajesh-arora"><img src={icon01} />Rajesh Arora</NavLink></li>
                                    <li class="stxmns"><NavLink to="#"><img src={icon01} />Jagjit Singh</NavLink></li>
                                    <li class="stxmns"><NavLink to="#"><img src={icon01} />Jagjit Singh</NavLink></li>
                                    <li class="stxmns"><NavLink to="#"><img src={icon01} />Jagdeep Singh Sandhu</NavLink></li>
                                    <li class="stxmns"><NavLink to="#"><img src={icon01} />Rajdeep Singh Benipal</NavLink></li>
                                                                        
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="sitembox">
                        <ul>
                            <li class="stxmn"><NavLink to="/csr">Our CSR</NavLink></li>                            
                            <li class="stxmn"><a href='https://jujhargroup.com/career'>Career</a></li>
                            <li class="stxmn"><a href="https://jujhargroup.com/newsroom">Newsroom</a></li>
                            <li class="stxmn"><NavLink to="/contact-us">Contact Us</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer />
    </>
  )
}
