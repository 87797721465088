import React from 'react'
import banner from './image/career-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import dots from "./image/icons.webp";
// import { Accordion, AccordionItem } from 'react-light-accordion';
import { Helmet } from 'react-helmet';
import career01 from './image/career01.webp'
import career02 from './image/career02.webp'
import career03 from './image/career03.webp'

export const Career = () => {
    document.title="Careers | Jujhar Group"
  return (
    <>
    <Helmet>
    <title>Careers | Jujhar Group</title>
      <meta name="description" content=""/>
    </Helmet>
        <Navbar />
        <section id='banner' className='p-0'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-right'>
                        <h2><span>Making people </span><span>smile.<em> Since 1990 </em></span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section id='csr'>
                <div className='csr position-relative'>
                <div className="dot-img position-absolute top-0 slow-move-anticlockwise">
                  <img src={dots} alt="" />
                </div>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='row'>
                            <div className='title'>
                                <h2>Our Career</h2>
                            </div>
                            <div className='csrcont text-center' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                               <p>
                               At Jujhar every employee brings a unique set of skills and experience to the table. Their wealth of knowledge in diverse sectors of the market provides a balanced push on our endless pursuit to bring perfect results towards our goals. We provide a professional and challenging work environment to our employees and believe in creating a nurturing team where everyone gets an opportunity to learn from each other.
                               </p><p>Jujhar is on a constant lookout for dynamic and self-driven professionals ready to bring the highest levels of service delivery. We also seek consulting partners with well-rounded network capabilities in focused family segments for collaborative opportunities.If your values match our philosophy, do get in touch and we will evaluate your career opportunities in our organization.
                               </p>
                               </div>
                            <div className='csrcont csr_descp career'>
                                <div className=' row'>
                                    <div className='col-md-5 col-xs-12' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <img src={career01} alt="" />
                                    </div>
                                    <div className='col-md-7 col-xs-12' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <div className='csrdecp'>
                                            <h3>Culture of Equality, diversity and Inclusion: </h3>
                                            <p>At Jujhar our culture is built on over decades of courageous decisions, in a workplace where co-creation and collaboration are embedded and where our core values of professionalism, respect, commitment and integrity shine through in everything we do. Our people have the opportunity to embark on a journey that’s shaping the world in a fast, innovative and ever-evolving technological landscape. Three pillars which help us to uphold the values are:</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className=' row'>
                                    <div className='col-md-5 col-xs-12' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <img src={career02} alt="" />
                                    </div>
                                    <div className='col-md-7 col-xs-12' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <div className='csrdecp'>
                                            <h3>Equality: </h3>
                                            <p>We believe that our businesses can be powerful platforms for social change, and that it is our responsibility to further enhance equality for all. Creating a culture of equality isn’t just the right thing to do, it’s also the smart thing. It empowers us to innovate, build deeper connections with our customers, and ultimately become a better company. We've designed our hiring, talent reviews and retention process with equality at the center.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className=' row'>
                                    <div className='col-md-5 col-xs-12' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <img src={career03} alt="" />
                                    </div>
                                    <div className='col-md-7 col-xs-12' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                        <div className='csrdecp'>
                                            <h3>Diversity and Inclusion: </h3>
                                            <p>We are here today at the helm of things because of thousands of different perspectives and values that empower our work culture and business at every corner of this company. We embrace diversity and inclusion, build teams that reflect that approach, and make space for our people to bring their true unique selves.</p>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className='csrcont'>
                            <div class="careerlink text-center">                            
                                <a href="mailto:career@jujhargroups.com" class="btn-3"><span>Email your details to career@jujhargroups.com</span></a>
                                </div>
                                {/* <div className='title'>
                                    <h2>Current Openings</h2>
                                </div> */}
    {/* <Accordion atomic={true}>
 
      <AccordionItem title="ASST. MANAGER - TAXATION AND INTERNAL AUDIT">
      <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </p>
      </AccordionItem>
 
      <AccordionItem title="ASST. MANAGER - TAXATION AND INTERNAL AUDIT">
      <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </p>
      </AccordionItem>
 
      <AccordionItem title="ASST. MANAGER - TAXATION AND INTERNAL AUDIT">
      <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </p>
      </AccordionItem>
 
    </Accordion> */}
    </div>
                        </div>
                        </div>
                    </div>
                </div>
        </section>
        <Footer/>
    </>
  )
}
