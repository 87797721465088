import React from 'react';
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import Number from "./Number"
import dots from "./image/icons.webp";
import jujhargrouplogo from "./image/jujhar-group.webp";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "aos/dist/aos.css";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
export const About_us = () => {
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <Helmet>
        <title>About Jujhar Group Ludhiana Punjab | Making People Smile. Since 1990.</title>
        <meta name="description" content="Jujhar Group is a Ludhiana Punjab Based multidimensional conglomerate growing sectors namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure and Hospitality." />
      </Helmet>
      <Navbar />
      <Banner />
      <section id='tabs' >
        <div className="tabs position-relative overflow-hidden">
          <div className='circles'>
            <div className='bg-circle slow-move-anticlockwise'></div>
            <div className='sm-circle slow-move-anticlockwise'></div>
          </div>
          <div className='dot-img position-absolute slow-move-anticlockwise' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
            <img src={dots} title='Jujhar Group' alt="Jujhar Dots" />
          </div>
          <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
            <TabList data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
              <Tab>Jujhar Group overview</Tab>
              <Tab>Corporate Ethos</Tab>
            </TabList>
            <TabPanel>
              <div className='overview'>
                <div className="container">
                  <div className="web-container">
                    <div className="title" data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                      <h2>Jujhar Group overview</h2>
                    </div>
                    <div className="col-12 row text-center m-0">
                      <div className="col-md-12 col-xs-12">
                        <div className="heading" data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                          <h1>Over 34 years of <span>business <em>excellence</em></span></h1>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="aboutcont" data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                          <p>The Jujhar Group is a multidimensional conglomerate headquartered in Ludhiana, Punjab. Headed by Chairman S Gurdeep Singh, a pioneer of the Indian Transport industry, the Jujhar Group has an enviable market leadership position in its many verticals and has an impressive customer base in growing sectors namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure, Hospitality & Healthcare.</p>
                          <p>It owes its success and leadership position to its core philosophy of “touching every aspect of people's lives with high quality products and unparalleled service”, which has been a guiding principle for the group’s sustainable growth for over three decades.</p>
                          <p>With its roots in Punjab, Jujhar is a well-diversified group of sound companies having a shared mission and values aimed at making life better. Our businesses deliver quality offerings and reliable service across 150 million touch points every day. From bringing a myriad of TV channels to over 75% of households in Punjab, enabling high-speed internet for smart-living and delivering everyday essential goods, to using luxury transportation to make road travel as smooth as business class and developing integrated townships for refined living, Jujhar Group takes pride in consistently receiving high marks for the services we offer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Number />

              <div className='journey'>
                <div className='container'>
                  <div className='row'>
                    <div className='web-container'>
                      <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                        <h2>Our journey</h2>
                      </div>
                      <div className='jslider' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                        <Slider {...settings}>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>1990</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Year of Establishment of Jujhar Group</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>1996</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Jujhar Constructions and Travels Pvt. Ltd. commences</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>1998</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Added Car Carrier business</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2007</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Established MSO Fastway Transmissions</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2010</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Entered Realty Sector with Grand Walk</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2011</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Launched Alpha International City – 350 Acre Township in Amritsar</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2012</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Established Sky Ama Infra and Daily Post</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2014</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Founded Living India Punjabi News Channel | Launched Grand Walk, Ludhiana</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2015</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Launched Netplus Broadband</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2016</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Ranked No.1 in Car Transportation Segment</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2017</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Fastway Transmissions ranked Largest MSO in North India; 4th Largest in India</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2018</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Netplus becomes first operator to launch 1 Gig speeds in the region</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2020</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Netplus leads in share of gross Ads (SOGA) with 3 lakh customer base and over 3 Million Home passes on FTTH</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox'>
                              <div className='journeycircle'>
                                <h5>2022</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>Netplus achieves service reach of 400+ cities across 8 states, 5 Lakh + customer base and over 6M Home Passes on FTTH</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='journeybox last-child '>
                              <div className='journeycircle'>
                                <h5>2024</h5>
                              </div>
                              <div className='journeycontent'>
                                <p>7.5 Lakh + Customer base and over 8 M Home Passes On FTTH</p>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="ethos col-12" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                <div className="container">
                  <div className="web-container">
                    <div className="ethosbanner col-12">
                      <div className="ethosstn">
                        <div className='title' >
                          <h2>Corporate Ethos</h2>
                        </div>
                        <div className="row col-12 m-0">
                          <div className="col-md-6 col-xs-12" >
                            <div className="ethoscont" >
                              <h3>Vision<span>Where do we want to be?</span></h3>
                              <p>Among the most admired organizations across the nation.</p>
                              <h4>We will always be admired for</h4>
                              <ul>
                                <li>Empowering our stakeholders</li>
                                <li>Being trustworthy and dependable</li>
                                <li>Being professional and progressive</li>
                                <li>Being sensitive to our environment</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="ethoscont">
                              <h3>mission<span>What is our Purpose?</span></h3>
                              <p>To enrich lives.</p>
                              <ul>
                                <li><em>People drive us.</em>We actively contribute towards making people’s lives happier and more convenient.</li>
                                <li><em>The future excites us.</em> We constantly seek and deploy the latest technologies to deliver the best and most advanced services.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='value'>
                <div className='container'>
                  <div className='row'>
                    <div className='web-container'>
                      <div class="ethoscont text-center col-12 d-inline-block" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                        <h3 className='col-12 d-inline-block'>Our Values
                          <span className='color-black'>What are the principles that define our culture?</span></h3></div>
                      <div className='col-12' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Customer Delight</h3>
                                <p>We are focused on delivering exceptional services that enhance our customers’ quality of life and provide them with great value for their money. Customer centricity lies at the heart of Jujhar’s culture.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Employee Commitment</h3>
                                <p>We ensure that our people are provided with the resources and opportunities necessary for their optimum performance and growth. We encourage strong team building and bonding for the development of a positive work environment. Our people are key to our success.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Environmental Respect</h3>
                                <p>We are committed to using natural resources wisely and adhering to sustainable practices, in order to protect the health of our environment for the benefit of the generations ahead. Environmental sustainability is right at the top at Jujhar.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Regulatory Integrity</h3>
                                <p>We adhere strictly to all regulatory compliances and pursue frequent and open communication with the regulators. Doing things the right way is the only way at Jujhar.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Operational Excellence</h3>
                                <p>Our high standards for operations enable us to meet and exceed our customers’ expectations, and perform our work safely. At Jujhar, we believe the smoother our operations, the better our service.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <div class="slogo">
                              <div class="vcontent">
                                <h3>Financial Strength</h3>
                                <p>We are prudent custodians of our financial resources and invest diligently to ensure the growth of our businesses and their long-term profitability. Big dreams are achieved with big resources and judicious decision-making.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
      <Footer />
    </>
  );
};
