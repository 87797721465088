import React, {useState} from 'react'
import banner from './image/edutech.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { NavLink } from 'react-router-dom';
import logo1 from './image/ESS_Global.webp';
import icon1 from './image/consultation.webp';
import icon2 from './image/visa.webp';
import icon3 from './image/visa-complete.webp';
import icon4 from './image/bank-transfer.webp';
import icon5 from './image/Travel-Process-&-Documentation.webp';
import icon6 from './image/Health-Check-up.webp';
import icon7 from './image/Accommodatio-Process.webp';
import icon8 from './image/departure.webp';
import icon9 from './image/arrival.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot-eDUTECH.webp';

export const Edutech = () => {
    const [state, setState] = useState({var1:1,var2:1});
  return (
    <>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                    <h2><span>Universal Access to the <em>World’s Best Immigration</em> </span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>ESS GLOBAL</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>ESS Global </h2>
                                            <p>ESS Global is a globally recognized Immigration & Educational consultancy that provides universal access to the world’s best immigration, and has been serving students aspiring to study at multiple destinations abroad since 2001.</p>
                                            <p>With a vast network of 14 branches across North India, along with operations in and direct collaborations with major universities and colleges of high repute in Australia, Canada, UK, Singapore, Dubai and Cyprus - it is the leading immigration consultancy in the region and the trusted overseas education partner for the bright minds of tomorrow.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute'>
        <img src={dot_left} alt='' className='slow-move-anticlockwise'/>
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="" />
                                    <span>Career Counseling </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} alt="" />
                                    <span>Visa Support </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon3} alt="" />
                                    <span>Completion of Admission Process </span>
                                </div> 
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon4} alt="" />
                                    <span>Banking Process & Documentation </span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon5} alt="" />
                                    <span>Travel Process & Documentation </span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon6} alt="" />
                                    <span>Health Check-up Process & Documentation </span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon7} alt="" />
                                    <span>Accommodation Process & Documentation </span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon8} alt="" />
                                    <span>Pre-Departure Services </span>
                                </div>  
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon9} alt="" />
                                    <span>Post Arrival Services</span>
                                </div>                                     
                                    
                                    
                                                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1==1) ? 1 : 14} end={14} duration={1} onEnd={()=> setState({var1:14})} /> : <CountUp start={1} end={14} duration={0} />}
        
        <div className='labelcont'>
          <em>branches across North India </em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var2==1) ? 1 : 12000} end={12000} duration={3} onEnd={()=> setState({var2:12000})}/> : <CountUp start={1} end={12000} duration={0} />}
             <p> </p>          
            <div className='labelcont'>
             <em>Student Visas Delivered</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
      
      
                              
                            </div>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Leading immigration <span> & educational consultancy in the region </span></li>
                                    <li>Operations in <span>Australia, Canada, UK, Singapore, Dubai and Cyprus </span></li>
                                    <li>Direct collaboration with  <span>major universities & colleges in countries of operations </span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://essglobal.com/" class="btn-3" target='_blank' rel="noreferrer"><span>www.essglobal.com</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
