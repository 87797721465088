import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/S-Gurdeep-Singh-inner.webp';

export const Leadershipdetails = () => {
  return (
    <>
      <Helmet>
    <title>S GURDEEP SINGH | Founder and Chairman Jujhar Group</title>
      <meta name="description" content="S Gurdeep Singh is a natural leader, committed towards driving growth and change. Having graduated from the Government College Ludhiana in 1985, he joined his family transport business soon after."/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>S Gurdeep Singh</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>S Gurdeep Singh</h1>
                                <h2><span>Founder and Chairman</span> <span>Jujhar Group</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='S Gurdeep Singh' title='S Gurdeep Singh' width='200' height='200'/>
                                <p>S Gurdeep Singh is a natural leader, committed towards driving growth and change. Having graduated from the Government College Ludhiana in 1985, he joined his family transport business soon after. At a very early stage, he realized the need for a professional logistics service that operated with uncompromised standards and precision, and in 1990, he founded Jujhar Constructions & Travels Pvt. Ltd.</p>
                                <p>A true visionary across multiple verticals, he recognized the value of high-quality broadcasting and expanded the company into Multi-Satellite Operations by establishing Fastway Transmissions, thereby revolutionizing the way Punjab watches TV. He also pioneered the setting up of an expansive optic fiber cable across Punjab and facilitated the explosion of high speed broadband internet with a multitude of allied smart applications with the introduction of Netplus Broadband.</p>
                                <p>He eventually also found his calling in Real Estate, and ventured into the Jujhar Group’s large-scale residential and commercial projects.</p>
                                <p>Under his leadership, the group has witnessed unprecedented progress and scaled up to expand into a multitude of high-growth industries, with operations across 8 states in North India. Currently, he resides on the board of over 20 companies under the Jujhar Group banner.</p>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
