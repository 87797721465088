import React, { useEffect, useState } from 'react'
import logo from './image/jujhar-group.webp';
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css"
import { Helmet } from 'react-helmet';

export const Navbar = () => {
  useEffect(() => {
    Aos.init({duration: 1000});
    window.addEventListener('scroll', changeBackground);             

    return () => {
      window.removeEventListener('scroll', changeBackground);
    }
   }, []);
   const canonical = window.location.href; console.log(canonical);
   const [menu, setActive] = useState("false");
      
        const handleToggle = () => {
          setActive(!menu);
        };
   const [header,setNavbar] = useState(false);
   const ddd = window.location.href; console.log(ddd);
    const changeBackground = () => {
     if(window.scrollY >= 80) {
       setNavbar(true);
     } else {
       setNavbar(false);
     }
    };
  return (
    <>
    <div>
    <Helmet>
        <link rel="canonical" href={canonical} />
    </Helmet>
</div>
    <header id='header'>
      <div className={header ? 'header fixed' : 'header'}>
    <div className='topstrip desktop-show'>
      <div className='container'>
          <div className='row'>
            <div className='col-md-12 justify-content-end d-flex'>
            <div className='col-lg-6 col-xs-12'>
              <ul className='topnav d-flex justify-content-end'>
                <li><a href='https://www.jujhargroup.com/career'>Career</a></li>
                <li><a href="https://www.jujhargroup.com/newsroom">Newsroom</a></li>
                <li><NavLink to="/contact-us">Contact Us</NavLink></li>
              </ul>
            </div>
            </div>
          </div>
        </div>
        </div>
        <div className='container'>
        <div className='navigation'>
          <div className='row align-items-end'>
            <div className='col-4'  data-aos="fade" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
              <div className='logo'>
                <NavLink to='/'><img src={logo} alt="Jujhar Group" title='Jujhar Group' width='174' height='73' /></NavLink>
              </div>
            </div>
            <div className='col-8'>
              <div className={menu ? 'menu' : 'menu active'} >
              <button onClick={handleToggle} className="hemburgur">
              <span></span>
              <span></span>
              <span></span>
              </button>
            <ul className='d-flex navbar' data-aos="fade" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                <li><NavLink to="/" exact>Home</NavLink></li>
                <li><NavLink to="/about-jujhar-group" >About Us</NavLink></li>
                <li><NavLink to="/businesses" >Our Businesses</NavLink></li>
                <li><NavLink to="/leadership">Our Leadership</NavLink></li>
                <li><NavLink to="/csr" >Our CSR</NavLink></li>
                <li className='mobile-show'><a href="https://www.jujhargroup.com/career">Career</a></li>
                <li className='mobile-show'><a href="https://www.jujhargroup.com/newsroom">Newsroom</a></li>
                <li className='mobile-show'><NavLink to="/contact-us">Contact Us</NavLink></li>
              </ul>
              </div>
              </div>
          </div>
          </div>
        </div>        
        </div>        
    </header>
    </>
  )
}
