import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/Prem-Ojha.webp';

export const Leadershipdetails3 = () => {
  return (
    <>
    <Helmet>
    <title>PREM OJHA | CEO Netplus and Fastway Transmissions</title>
      <meta name="description" content="Prem Ojha brings with him a passion for excellence in the telecom sector, with a vivid experience of over three decades. Strategic thinking and immaculate execution is his forte. "/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Dr. Prem Ojha</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Dr. Prem Ojha</h1>
                                <h2><span>CEO</span> <span>Netplus</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Prem Ojha' title='Prem Ojha' width='200' height='200'/>
                                <p>Prem Ojha brings with him a passion for excellence in the telecom sector, with a vivid experience of over three decades. He is an authority in the Internet services domain and has many firsts to his credit. He has a very successful track record of leading organizations to be best-in-class and achieving a dominant market leadership position.</p>
                                <p>Prior to joining Jujhar Group in 2017, he served as COO at Videocon Telecommunication and the Regional Head at Tata Communications.</p>
                                <p>After his B. Tech. in Electronics and Communications, Prem Ojha did his Masters in Marketing, followed by PhD in Management.</p>
                                <p>A harbinger in the evolution & growth of broadband services, he is credited with the successful launch of next generation converged services including FTTH based High Speed Broadband, Smart Telephony, ATV & OTT Services.</p>

                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
