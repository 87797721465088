import React from 'react'
import banner from './image/real-estate.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/jujhar_realty_red.webp';
import logo2 from './image/Grand_Walk_Mall.webp';
import logo3 from './image/Alpha.webp';
import logo4 from './image/green-city.webp'
import logo5 from './image/js-enclave-logo-grey.png'
import icon1 from './image/real-estate-01.webp';
import icon2 from './image/real-estate-02.webp';
import icon3 from './image/skyline.webp';
import icon4 from './image/coworking.webp';
import icon5 from './image/mall.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import snapshot from './image/snapshot-realestate.webp';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Realstate = () => {
    //const [state, setState] = useState({var1:1, var2:1, var3:1});
  return (
    <>
     <Helmet>
    <title>Jujhar Constructions: Building Dreams into Reality with Excellence</title>
      <meta name="description" content="Jujhar Constructions is a renowned realty company that turns dreams into reality with its excellent construction services. Jujhar realty."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner real-state' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Building the Cities of Tomorrow, <em>Today</em> </span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>Jujhar Realty</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />      
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt='Jujhar Realty' title='Jujhar Realty' width='200' height='200'/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Jujhar Realty </h1>
                                            <p>Jujhar Realty is the land acquisition and development arm of the Jujhar Group, with plans to develop mega commercial and residential township projects. It brings together the best talent pool of planners, architects, and engineers to ensure that projects are meticulously executed.</p>
                                            <p>In a short span of time, Jujhar Realty has already made a mark with three delivered projects in Ludhiana and Amritsar.</p>
                                            <p>Grand Walk in Ludhiana is a hybrid of modern offices and retail stores, and Alpha International City is a lush and ultra-modern township, spread across 350 acre in Amritsar.</p>
                                            <p>Jujhar Realty’s forthcoming landmark developments include a mega Exhibition Centre, an ultra modern mall, community of luxurious mansions, and a 180 room Marriott Hotel in Ludhiana.</p>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute'>
        <img src={dot_left} alt='' className='slow-move-anticlockwise'/>
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="Land acquisition, licensing" title='Land acquisition, licensing' width='70' height='70'/>
                                    <span>Land acquisition,<br/>licensing </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} alt="Residential" title='Residential' width='70' height='70'/>
                                    <span>Residential</span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon3} alt="Commercial" title='Commercial' wdth='70' height='70'/>
                                    <span>Commercial</span>
                                </div> 
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon4} alt="Work Spaces" title='Work Spaces' width='70' height='70'/>
                                    <span>Work Spaces</span>
                                </div> 
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon5} alt="High Street and Malls" title='High Street and Malls' width='70' height='70' />
                                    <span>High Street and Malls</span>
                                </div>                           
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo2} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>Grand Walk, Ludhiana</h2>
                                            <p>Grand Walk is a thriving commercial development situated on the prime Ferozepur Road in Ludhiana.</p>
                                            <p>The complex is built on a commercial site measuring 49,500 sq.ft., with a constructed area of 2.4 lakh sq.ft. – comprised of 6 floors and 2 basements with parking facilities.</p>
                                            <p>The ground floor is dedicated to high-end retail and F&B with reputed brands like H&M, Kapsons, Burger King, Pizza Hut, etc. while the top 5 floors host modern work spaces.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                <li>8 high speed elevators + <span>2 service elevators</span></li>
                                <li>100% <span>power backup</span></li>
                                <li>24x7 central<span> Wi-Fi connectivity </span></li>
                                <li>Centrally air-conditioned<span>complex with Automatic Climate Control</span></li>
                                <li>Complete CCTV coverage<span>for safety and security</span></li>
                                <li>Valet parking at both <span>basement and ground levels </span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                {/* <div className='web'>
                  <div class="backbtn text-center">
                      <a href="http://grandwalk.co.in/" class="btn-3" target='_blank' rel="noreferrer"><span>www.grandwalk.co.in</span></a>
                      </div>
                  </div> */}
            </section>
            <section id='entertainement2'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo3} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>Alpha International City, Amritsar</h2>
                                            <p>Designed by leading architects ARCOP of Montreal, Canada in association with premiere builders Alpha Corp, Alpha International City is one of the largest fully-integrated residential & commercial townships in Amritsar.</p>
                                            <p>Spanning over 350 acre, it is situated at a prime location in Amritsar, adjacent to the NH 1 and within close proximity to the city centre – minutes away from the Golden Temple.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers2'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Largest township<span>in Amritsar</span></li>
                                    <li>Meticulously planned <span>villas and apartments with world-class facilities</span></li>
                                    <li>Luxurious<span>community club, shopping complexes, healthcare facilities, education facilities and an independent commercial zone</span></li>
                                    <li>Vast<span>landscaped greens, water features and multiple parks</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
               
            </section>
            <section id='entertainement3'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo4} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>Green City Residency, Ludhiana</h2>
                                            <p>Green City Residency is an exclusive community of select plots located just off the thriving Ferozepur Road of Ludhiana. Spaciously designed and ideally located within close proximity to the best of social infrastructure like schools, hospitals, retail and commercial zones, our lavish plots provide the perfect opportunity to build your legacy from the ground up and pass it on to the next generation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers3'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Gated Complex<span> with regulated entry & exit</span></li>
                                    <li>24x7<span>CCTV surveillance</span></li>
                                    <li>40-feet  <span>wide internal roads</span></li>
                                    <li>Underground <span> electrical wiring</span></li>
                                    <li>Provision for power<span> backup in common areas</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
               
            </section>
            <section id='entertainement4'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo5} alt='' style={{width: '100%'}} />
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>JS Enclave, Ludhiana</h2>
                                            <p>JS Enclave is a premium plotted development in Ludhiana, designed to offer its residents the best in luxury living, with a range of high-end features and amenities. Providing a spacious and grand feel to the community with 30 ft. wide roads, the site has ample open spaces and parks, providing residents plenty of opportunities to enjoy the outdoors and stay active.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers4'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Spread over 10 acres<span> located at Sunder Nagar Chowk, Ludhiana</span></li>
                                    <li>24 Hours<span>gated Security with CCTV surveillance</span></li>
                                    <li> Parks & Playing<span>Area</span></li>
                                    <li>Open <span> Gym Facilities</span></li>
                                    <li>Underground <span> Wiring</span></li>
                                    <li>Underground<span> Optical Fiber Fitting</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className='web'>
                  <div class="backbtn text-center">
                  <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>
                  <a className="btn-3" href='https://jujharrealty.com' target='_blank'><span>www.jujharrealty.com</span></a>
                      </div>
                  </div>
            </section>
        <Footer/>
    </>
  )
}
