import React, {useState} from 'react'
import banner from './image/Healthcare-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/jujhar-healthcare-black.webp';
import dots from "./image/icons.webp";
import icon1 from './image/Maternal.webp';
import icon2 from './image/Gynaecology.webp';
import icon3 from './image/Fetal.webp';
import icon4 from './image/Neonatology.webp';
import dot_left from './image/icons.webp'
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Healthcare = () => {
    const[state, setState] = useState({var1:1, var2:1});
  return (
    <>
     <Helmet>
    <title>Jujhar Healthcare - Jujhar Group Punjab</title>
      <meta name="description" content="The Jujhar Group, a Ludhiana-based company with diversified business interests, has forayed into the hospitality sector by acquiring the Fairfield by Marriott Hotel Amritsar."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner hospitality' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                    <h2><span>Driven by <em>innovation</em>, Delivered with care.</span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </section>
            <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>Jujhar Healthcare</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-ea sing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />  
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt='Hospitality' title='Hospitality' width='200' height='200'/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Jujhar Healthcare</h1>
                                            <p>Jujhar Group aims to transform the health ecosystem of Punjab by working towards the development and improvement of proper infrastructure required for providing the highest level of health and medical facilities in the region.</p>
                                            <p>Recognising the importance and necessity for providing quality healthcare services in the country, the Jujhar Group has ventured into the healthcare industry via its association with Apollo Health & Lifestyle Limited.</p>
                                            <p>As part of the association, a new Apollo Cradle & Children’s Hospital will be built in the heart of Ludhiana, with a focus on providing comprehensive maternal and pediatric care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute slow-move-anticlockwise'>
        <img src={dot_left} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="Passenger Transport – AC and Luxury Buses"  title='Passenger Transport – AC and Luxury Buses' width='70' height='70'/>
                                    <span>Maternal and Children’s Care Specialising in Maternity </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} alt="Logistics Provider – Car Transportation"  title='Logistics Provider – Car Transportation' width='70' height='70'/>
                                    <span>Gynaecology Paediatrics</span>
                                </div>           
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon3} alt="Logistics Provider – Car Transportation"  title='Logistics Provider – Car Transportation' width='70' height='70'/>
                                    <span>Fetal Medicine</span>
                                </div>       
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon4} alt="Logistics Provider – Car Transportation"  title='Logistics Provider – Car Transportation' width='70' height='70'/>
                                    <span>Neonatology & Fertility</span>
                                </div>                      
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                             
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}

