import React from 'react'
import banner from './image/about-banner-new.png';
import banner2 from './image/about-banner-mobile-new.png';

export const Banner = () => {
  return (
    <>
    <section id='banner' className='p-0 about-banner'>
        <div className='banner desktop-show' style={{ backgroundImage: `url(${banner})` }}>
            {/* <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2><span>Commited to the future. <em>Rooted in values.</em></span></h2>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='banner mobile-show' style={{ backgroundImage: `url(${banner2})` }}>
            {/* <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2><span>Commited to the future. <em>Rooted in values</em></span></h2>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    </>
  )
}
