import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Privacy_policy = () => {
  return (
    <>
    <Navbar />
    <section id='content'>
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                                <div className='title'>
                                    <h2>Privacy policy</h2>
                                </div>
                                <p>At the Jujhar Group, we respect your privacy, and are committed towards ensuring the privacy and security of the personal information of all visitors on our corporate website. We have thus adopted the following Privacy Policy, which is applicable to all Personally Identifiable Information (PII) provided by you to the Jujhar Group via our website – </p>
                                <ul>
                                  <li>For those visiting our website to browse through or download information, it should be known that standard internet-related information such as the visitor’s Internet Protocol (IP) address, date and time, browser and operating system, and the website pages visited, is collected automatically for the purpose of statistical analysis aimed towards providing a smoother user experience on the website and obtaining a better understanding of what our customers seek and require for driving marketing campaigns and communications for which the visitor has provided their consent. </li>
                                  <li>For data shared by visitors voluntarily towards the submission of queries, requests and/or prospective career opportunities at the Jujhar Group in the form of information such as the visitor’s name, contact number, email address, etc., the Jujhar Group collects and utilizes such information towards responding to such queries, requests and job applications. We do not share such information with any third parties, unless the query, request and/or career opportunity pertains to one of our subsidiary companies, in which case the information is shared with the specific subsidiary company under the Jujhar Group and shall be governed by the privacy policy of that specific subsidiary. </li>
                                </ul>
                                <p>Except specifically stated in the privacy policy above, the Jujhar Group shall abstain from disclosing any personally identifiable information without the consent of the individual, unless the situation presents itself where the Jujhar Group is legally obligated to do so by law or for the protection of its own rights and interests. </p>
                                <p>The Jujhar Group also reserves the right to amend and/or modify any and all terms of its privacy policy at any instance in time that it deems appropriate, without the obligation to provide any prior notice or intimation regarding the same. </p>

                                <h3>Cookie Policy</h3>
                                <p>Kindly be informed that this website places and accesses certain Cookies on your device for the purpose of improving your user experience. As such, this website specifically displays a message requesting your consent before placing any Cookies on your device. You may, as per your preference, accept or deny consent to the placement of Cookies on your device when presented with this message; however please note that the denial of consent for the same may limit the functionality of certain features as intended on the website. As standard, most internet browsers accept the use of Cookies by default; however this may be enabled or disabled by you in your internet browser. </p>
                        </div>
                    </div>
                </div>
            </section>    
    <Footer />
    </>
  )
}
