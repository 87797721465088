import React from 'react'
import banner from './image/living-india-news.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Living_India_News.webp';
import logo2 from './image/punjabi_hits.webp'
import dots from "./image/icons.webp";
import snapshot from './image/snapshot-media.webp';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export const LivingIndia = () => {
    //const [state, setState] = useState({var1:1, var2:1});
  return (
    <>
    <Helmet>
    <title>Living India News | Jujhar Group Ludhiana Punjab</title>
      <meta name="description" content="Jujhar Group - Living India News is a 24x7 satellite news channel with a deep focus on the North Indian states of Punjab, Haryana, Himachal Pradesh, Jammu & Kashmir and New Delhi."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner livingindia' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>A Leading Satellite <em>News Channel</em> </span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>LIVING INDIA NEWS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Living India News</h1>
                                            <p>Living India News is a 24x7 satellite news channel with a deep focus on the North Indian states of Punjab, Haryana, Himachal Pradesh, Jammu & Kashmir and New Delhi.</p>
                                            <p>With a large and ever-growing viewership on account of its extensive coverage of important events and its commitment towards extending its reach to all sects of viewers within India as well as abroad, it is fast advancing to become a pioneer regional news channel and one of the most widely watched among the Indian news television industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                <li>Ranked No.1<span>Satellite News Channel in Punjab</span></li>
                                <li>Rated 10.3 <span>by BARC</span></li>
                                <li>Pan-India <span>Viewership</span></li>
                                <li>Collaboration with all Major service providers <span>such as Tata Sky, Airtel, DEN, Fastway, Hathway, and Siti Cable</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">                 
                                <a href="https://livingindianews.co.in/" class="btn-3" target='_blank' rel='noreferrer'><span>www.livingindianews.co.in</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
            <section id='punjabientertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo2} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Punjabi Hits</h1>
                                            <p>Launched in 2021 as a dedicated channel for Punjabi music, Punjabi Hits is fast emerging as the preferred regional music channel for the youth. With an extensive collection of both the latest as well as evergreen music tracks, it is particularly popular among younger audiences and growing at an accelerated pace due to its witty and quirky graphics, analytical and creative music programs, meaningful conversational formats, and real-time updates from the music world of Punjab. </p>
                                            <p>The platform also goes a step further by supporting and promoting fresh young talent in the field of music, with many exciting programs such as music talent hunt shows, music grooming shows, youth-centric music programs, original musical content, behind-the-scenes shows and fun events based on Punjabi music in the pipeline as well. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='punjabinumbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                <li>Launched in <span>2021</span></li>
                                <li>Fast Emerging <span>Regional Music Channel </span></li>
                                <li>Extensive collection <span>of Latest and Evergreen Punjabi tracks</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='punjabibusinessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
