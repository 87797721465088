import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/Gurinder-Bhatti.webp';

export const Leadershipdetails4 = () => {
  return (
    <>
      <Helmet>
    <title>PREM OJHA | CEO Netplus and Fastway Cables</title>
      <meta name="description" content="Prem Ojha brings with him a passion for excellence in the telecom sector, with a vivid experience of over three decades. Strategic thinking and immaculate execution is his forte. "/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Gurinder Bhatti</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Gurinder Bhatti</h1>
                                <h2><span>Chairman & Managing Director</span> <span>ESS Global</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Gurinder Bhatti'/>
                                <p>Gurinder Bhatti is a seasoned professional at the helm of ESS Global, responsible for handling the entire organization with a major focus towards financial planning, in addition to handling sales and marketing for the whole group.</p>
<p>Gurinder’s strong business acumen led him to pursue Business Management at Schiller International University’s Waterloo Campus in the UK. A certified OISC (Office of Immigration Services Commissioner), he also took trained towards the role of being an educational agent and some of the most premiere institutes in the world. With ESS Global, he has managed to create a platform that gives deserving students a chance to study abroad at some of the best international universities through a legal, cost-effective, and hassle-free approach.</p>
<p>Gurinder has been recognized for his contributions by several reputed boards. He won the ‘Young Entrepreneur of the Year’ award of North India in 2014 organized by India Today group and was felicitated by the Former Chief Minister of Punjab, Shri Prakash Singh Badal. Furthermore, he has been also felicitated with the Achievers Award by PTC group, and has had the honor of receiving the same from Former Deputy Chief Minister of Punjab, Shri Sukhbir Singh Badal. In 2019, Mr. Gurinder was awarded the “Prominent Sikh Award” by the News18 channel.</p>
<p>Apart from being an entrepreneur, he is also a philanthropist, and formed Express Social Services, an NGO that takes up a variety of social causes, such as providing medical facilities to the needy and conducting marriage ceremonies for girls of legal marriageable age, who come from a humble background.</p>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
