import React from 'react'
import banner from './image/contact-banner.webp';
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet'
import logo1 from './image/Fastway.webp';
import logo2 from './image/Netplus_Broadband.webp';
import logo4 from './image/Daily_Post_Punjabi.webp';
import logo11 from './image/Living_India_News.webp';
import logo12 from './image/punjabi_hits_internal.webp';
import logo7 from './image/Sky_Ama_Infra.webp';
import logo10 from './image/jujhar-logistic.webp';
import logo5 from './image/Grand_Walk_Mall.webp';

export const Contact_us = () => {
  return (
    <>
        <Helmet>
    <title>Jujhar Group - Contact us for Sales, Business and customer support queries for all Jujhar Businesses</title>
      <meta name="description" content="Sales query India: +91-161-4044444, Email: corporate@jujhargroup.com, Address: Level 5, Grand Walk Ferozepur Road, Ludhiana, Punjab 141012"/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 contactbanner'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            
        </div>
    </section>
    <section>
        <div className='contact-us'>            
        <div className='container'>
            <div className='row'>
            <div className='web-container'>
                <div className='title'>
                    <h1>Contact us</h1>
                </div>
                <div className='contactdetail'>
                        <div className='contactbox'>
                        <h2>Jujhar Group</h2>
                            <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-4044444'>+91-161-4044444</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>corporate@jujhargroup.com</a></p></li>
                                <li><strong>Address</strong>
                                <p>Level 5, Grand Walk <br/>Ferozepur Road, Ludhiana <br/> 141012 Punjab, India</p>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div className='contactdetail'>                            
                        <div className='contactbox'>
                                <h2>Jujhar Logistic</h2>
                                <img src={logo10} />
                                <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-2562433'>+91-161-2562433</a><br/><a href='tel:+91-161-2562339'>+91-161-2562339</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>corporate@jujhargroup.com</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="https://jujhar.in/index.aspx" target="_blank"  rel="noreferrer"><span>www.jujhar.in</span></a></div>
                        </div>
                        <div className='contactbox'>
                        <h2>Fast way</h2>
                        <img src={logo1} />
                            <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-4044444'>+91-161-4044444</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:fastway@fastway.in'>fastway@fastway.in</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="https://fastway.in/" target="_blank"  rel="noreferrer"><span>www.fastway.in</span></a></div>
                        </div>
                        
                        <div className='contactbox'>
                                <h2>NETPLUS BROADBAND</h2>
                                <img src={logo2} />
                                <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-4044444'>+91-161-4044444</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:info@netplus.co.in'>info@netplus.co.in</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="https://www.netplus.co.in/" target="_blank"  rel="noreferrer"><span>www.netplus.co.in</span></a></div>
                        </div>
                        </div>
                        <div className='contactdetail'>
                        <div className='contactbox'>
                        <h2>Daily Post</h2>
                        <img src={logo4} />
                            <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-2562433'>+91-172-5201100</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>info@dailypost.in</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="https://dailypost.in/" target="_blank"  rel="noreferrer"><span>www.dailypost.in</span></a></div>
                        </div>
                        <div className='contactbox'>
                        <h2>BPL Media Limited</h2>
                        <img src={logo11}/><img src={logo12} class="margin10Imp"/>
                            <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-2562433'>+91-017-266697752</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>info@livingindianews.co.in</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="https://livingindianews.co.in/" target="_blank"  rel="noreferrer"><span>www.livingindianews.co.in</span></a></div>
                        </div>
                        <div className='contactbox'>
                        <h2>Real Estate</h2>
                        <img src={logo5} />
                            <ul>
                                {/* <li><strong>Call</strong><p><a href='tel:+91-161-2562433'>+91-161-2562433</a></p></li> */}
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>corporate@jujhargroup.com</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="http://grandwalk.co.in/" target="_blank"  rel="noreferrer"><span>www.grandwalk.co.in</span></a></div>
                        </div>
                        </div>
                        <div className='contactdetail'>
                        <div className='contactbox'>
                        <h2>Infrastructure</h2>
                        <img src={logo7} />
                            <ul>
                                <li><strong>Call</strong><p><a href='tel:+91-161-4044489'>+91-161-4044489</a><br/><a href='tel:+91-161-4044490'>+91-161-4044490</a></p></li>
                                <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>info@skyama.co.in</a></p></li>
                            </ul>
                            <div class="aligncenter"><a class="btn-3" href="http://skyama.co.in/" target="_blank"  rel="noreferrer"><span>www.skyama.co.in</span></a></div>
                        </div>
                        </div>
            </div>            
            </div>
        </div>
        </div>
    </section>
    <Footer />
    </>
  )
}
