import React, {useState} from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import banner from './image/fastway-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Fastway.webp';
import icon1 from './image/icon01.webp';
import icon2 from './image/icon02.webp';
import icon3 from './image/icon03.webp';
import icon4 from './image/icon04.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import { NavLink } from 'react-router-dom';
import snapshot from './image/snapshot-entertainment.webp';
import { Helmet } from 'react-helmet';

export const Entertainment = () => {
  const [state, setState] = useState({var1:1, var2:1, var3:1, var4:1, var5:1, var6:1, var7:1, var8:1, var9:1, var10:1, var11:1, var12:1, var13:1, var14:1});
  return (
    <>
      <Helmet>
    <title>Fastway Wifi, Transmissions, and Cable Broadband Plans</title>
      <meta name="description" content="Fastway offers reliable Wifi services and cable broadband plans. Enjoy seamless transmissions and lightning-fast connectivity with Fastway."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner entertainment' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Digital</span> <em>Entertainment </em> <span>in HD</span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>Fastway Transmissions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='200' height='200'/>                
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} title='Fatway' alt="Fastway" width='200' height='200'/>           
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Fastway Transmissions</h1>
                                            <p>Fastway Transmissions is a pioneer in digital entertainment services and is the largest multiple-system operator (MSO) in North India.</p>
                                            <p>Having commenced operations in 2007, the company has established strong infrastructure by laying down an extensive network of Optic Fiber Cable across Punjab, as well as other major regions in North India such as Haryana, Jammu & Kashmir, Rajasthan, Himachal Pradesh, Uttarakhand, Delhi NCR and Uttar Pradesh.</p>
                                            <p>Fulfilling the entertainment appetite through 400 SD+HD channels, Fastway is a household name in North India. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute'>
        <img src={dot_left} alt='' className='slow-move-anticlockwise'/>
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} title='Digital entertainment through 400 SD+HD channels' alt="Digital entertainment through 400 SD+HD channels" width='70' height='70'/>  
                                    <span>Digital entertainment through 400 SD+HD channels</span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} title='Fastway news and entertainment' alt="Fastway news and entertainment" width='70' height='70'/>  
                                    <span>Fastway news and entertainment </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon3} title='Next-gen Android TV  & OTT' alt="Next-gen Android TV  & OTT" width='70' height='70'/>  
                                    <span>Next-gen Android TV  & OTT </span>
                                </div>                                
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon4} title='Non-stop ad-free channels' alt="Non-stop ad-free channels" width='70' height='70'/>  
                                    <span>Non-stop <br />ad-free channels </span>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
            <h2>Snapshot</h2> </div>
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
                        <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1===1) ? 1 : 95} end={95} duration={1} onEnd={()=> setState({var1:95})}/> : <CountUp start={1} end={95} duration={0} />}%
             <div className='labelcont'>
               <em>Market Share</em>
             </div>
            </div>
          )}
        </VisibilitySensor>           
      </div>
                            
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var2===1) ? 1 : 3000} end={3000} duration={1} onEnd={()=> setState({var2:3000})}/> : <CountUp start={0} end={3000} duration={0} />}+  
             <div className='labelcont'>
          <em>professionals </em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 1} end={2.5} duration={2.5} separator=" " decimals={1}  decimal="."  onEnd={()=> setState({ workforce:2.5 })} /> : <CountUp start={1} end={2.5} duration={0} />} 
             <p>Crore</p>
             <div className='labelcont'>
               <em>viewers</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var4===1) ? 1 : 8} end={8} duration={1} onEnd={()=> setState({var4:8})}/> : <CountUp start={1} end={8} duration={0}/>}
             <p></p>     
             <div className='labelcont'>
          <em>States </em>
        </div>
            </div>
          )}
        </VisibilitySensor>
      
      </div>
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var5===1) ? 1 : 300} end={300} duration={1} onEnd={()=> setState({var5:300})}/> : <CountUp start={1} end={300} duration={0} />} +
             <div className='labelcont'>
               <em>Cities</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var6===1) ? 1 : 21000} end={21000} duration={3} onEnd={()=> setState({var6:21000})}/> : <CountUp start={1} end={21000} duration={0} />} +
             <p>km</p> 
             <div className='labelcont'>
               <em>Optic Fiber cable </em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var8===1) ? 1 : 5} end={5} duration={1} onEnd={()=> setState({var8:4})}/> : <CountUp start={1} end={5} duration={0} />} 
             <p>million</p> +
             <div className='labelcont'>
               <em>digital cable subscribers</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var9===1) ? 1 : 1} end={1} duration={1} onEnd={()=> setState({var9:1})}/> : <CountUp start={1} end={1} duration={0} />}<sup>st</sup>
             <div className='labelcont'>
               <em>underground infrastructure</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var10===1) ? 1 : 100} end={100} duration={1} onEnd={()=> setState({var10:100})}/> : <CountUp start={1} end={100} duration={0} />}+
             <div className='labelcont'>
               <em>Channels Largest local content</em>
             </div>
            </div>
          )}
        </VisibilitySensor>           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var11===1) ? 1 : 280000} end={280000} duration={1} onEnd={()=> setState({var11:280000})}/> : <CountUp start={1} end={280000} duration={0} />}+ <p>km</p>
             <div className='labelcont'>
               <em>OF EXTENSIVE FIBER NETWORK</em>
             </div>
            </div>
          )}
        </VisibilitySensor>           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var13===1) ? 1 : 400} end={400} duration={1} onEnd={()=> setState({var13:200})}/> : <CountUp start={1} end={400} duration={0} />}+
             <div className='labelcont'>
               <em>seater In-house call center </em>
             </div>
            </div>
          )}
        </VisibilitySensor>           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var14===1) ? 1 : 10000} end={10000} duration={1} onEnd={()=> setState({var14:10000})}/> : <CountUp start={1} end={10000} duration={0} />}+
             <div className='labelcont'>
               <em>local service partners</em>
             </div>
            </div>
          )}
        </VisibilitySensor>           
      </div>
                              
                            </div>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Copyrights for live telecast <span>  in majority of religious locations across Punjab, Haryana and Rajasthan </span></li>
                                    <li>Customer service – 99.99% <span>resolution rate – customer first approach </span></li>
                                    <li>24x7 <span> customer care service  </span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://fastway.in/" class="btn-3" target='_blank' rel="noreferrer"><span>www.fastway.in</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
