import React from 'react'
import banner from './image/net-plus-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Living_India_News.webp';
import icon1 from './image/net-plus-1.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot2.webp';

export const Satellite = () => {
  return (
    <>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Digital</span> <em>entertainment </em> <span>in HD.</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} alt='' />                    
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt=''/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h2>BPL Media – Living India News </h2>
                                            <p>Living India News is a 24x7 satellite news channel with a deep focus on the North Indian states of Punjab, Haryana, Himachal Pradesh, Jammu & Kashmir and New Delhi. Living India News is revered to be one of the most trusted and credible news and lifestyle networks among North - Indian viewers. </p>
                                            <p>With a large and ever-growing viewership on account of its extensive coverage of important events and its commitment towards extending its reach to all sects of viewers within India as well as abroad, it is fast advancing to become a pioneer Hindi (TV) channel and one of the most widely watched among the Indian news television industry. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute'>
        <img src={dot_left} alt='' className='slow-move-anticlockwise'/>
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="" />
                                    <span>Satellite news channel </span>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={1} end={5} duration={3} /> : <CountUp start={1} end={5} duration={0} />}
             <p>lakh +</p>   
        
        <div className='labelcont'>
          <em>Subscriber Base</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={1} end={2000} duration={2} /> : <CountUp start={1} end={1} duration={0} />}+             
            <div className='labelcont'>
             <em>premium enterprise customers</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
      
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={1} end={6} duration={3} /> : <CountUp start={1} end={6} duration={0} />} 
             <p>million +</p>
             <div className='labelcont'>
               <em>home passes of FTTH</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={1} end={300} duration={3} /> : <CountUp start={1} end={300} duration={0}/>}+
             <div className='labelcont'>
          <em>cities & presence in 9 states</em>
        </div>
            </div>
          )}
        </VisibilitySensor>
      
      </div>
                              
                            </div>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Triple Play Services<span>Voice + Video+ Data</span></li>
                                    <li>State-Of-The-Art<span>NOC & DWDM network</span></li>
                                    <li>Market leader in SOGA<span>(Share of Gross Ads)</span></li>
                                    <li>Collaboration with Facebook<span>Free Wi-Fi services</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </>
  )
}
