import React from 'react'
import circle from './image/circle.webp';
import dotimg from './image/dot.webp';
import logo from './image/ftrlogo.webp'
import { NavLink } from 'react-router-dom';
import facebook from './image/facebook.webp'
import instagram from './image/instagram.webp'
import linkedin from './image/linkedin.webp'
import twitter from './image/twitter.webp'
import youtube from './image/youtube.webp'

export const Footer = () => {
  return (
    <footer id='footer'>
    <div className='footer'>
      <div className='footer-container'>
          <div className='row'>
            <div className='col-md-4 col-sm-6 col-xs-12'>
                <div className='about'>
                  <div className='cicleimg'>
                  <img src={circle} alt='Jujhar Circle' title='Jujhar Group' width='326' height='301'/>
                  </div>
                    <div className='logo'>
                        <img src={logo} alt="Jujhar Group Logo" title='Jujhar Group' width='248' height='105'/>
                    </div>
                </div>
            </div>
            <div className='col-md-8 col-sm-6 col-xs-12 d-flex'>
              <div className='col-md-8 col-sm-6 col-xs-12'>
                  <div className='contactdetails'>
                    <ul>
                      <li><strong>Call</strong><p><a href='tel:+91-161-4044444'>+91-161-4044444</a></p></li>
                      <li><strong>Email</strong><p><a href='mailto:corporate@jujhargroup.com'>corporate@jujhargroup.com</a></p></li>
                    </ul>     
                  </div>
                  <div className='socialmedia'>
                    <h4>Follow Us</h4>
                    <ul className='d-flex'>
                    <li><a href='https://www.instagram.com/thejujhargroup/' target='_blank' rel="noreferrer"><span><img src={instagram} /></span></a></li>
                      <li><a href='https://www.facebook.com/JujharGroup/' target='_blank' rel="noreferrer"><span><img src={facebook} /></span></a></li>
                      <li><a href='https://www.linkedin.com/company/jujhargroup/' target='_blank' rel="noreferrer"><span><img src={linkedin} /></span></a></li>
                      <li><a href='https://twitter.com/jujhargroup' target='_blank' rel="noreferrer"><span><img src={twitter} /></span></a></li>
                      <li><a href='https://www.youtube.com/@JujharGroup' target='_blank' rel="noreferrer"><span><img src={youtube} /></span></a></li>
                    </ul>
                  </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12'>
              <h4>Jujhar Corporate Office</h4>
                  <p>Level 5, Grand Walk <br/> Ferozepur Road, Ludhiana <br/> 141012 Punjab, India</p>
              </div>
            </div>
          </div>
          <div className='dotimg'>
                  <img src={dotimg} alt='dot icons' title='dot icons' width='419' height='215'/>
                  </div>
          
    <div className='copyright'>
            <div className='row'>
                <div className='col-12 d-flex'>
                  <div className='col-md-6 col-xs-12'>
                    <ul className='d-flex'>
                      <li><a>&copy; 2023 | all rights reserved</a></li>
                        <li><NavLink to='/sitemap'> sitemap</NavLink></li>
                        <li><NavLink to='/disclaimer'>disclaimer</NavLink></li>
                        <li><NavLink to='/privacy-policy'>privacy policy</NavLink></li>
                    </ul>
                  </div>
                  <div className='col-md-6 col-xs-12'>
                    <ul className='d-flex justify-content-end'>
                        <li><a href='https://triverseadvertising.com/' target="_blank" rel="noreferrer">design : triverse</a></li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>   
  </footer>
  )
}
