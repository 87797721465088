import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/Arshdeep-Singh-Mundi-inner.webp';

export const Leadershipdetails2 = () => {
  return (
    <>
        <Helmet>
    <title>ARSHDEEP SINGH MUNDI | Executive Director Jujhar Group</title>
    <meta name="description" content=""/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Arshdeep Singh Mundi</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Arshdeep Singh Mundi</h1>
                                <h2><span>Executive Director</span> <span>Jujhar Group</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership01} alt='Arshdeep Singh Mundi' title='Arshdeep Singh Mundi' width='200' height='200'/>
                                <p>Arshdeep Singh Mundi belongs to the next generation of entrepreneurs, taking Jujhar Group to new heights with his proficient leadership and unshakeable ambition. He is driving the new age innovations and modernization efforts of the group by foraying into tech-led businesses.</p>
<p>An undergraduate from the Royal Holloway University of London, and postgraduate from the prestigious Indian School of Business, Arshdeep directed the expansion of Fastway Transmissions with the ambitious plan of laying a 6000 km optical fiber network, connecting over 300 cities and villages across North India.</p>
<p>He is also credited with the state-of-the-art automation of the group’s flagship logistics business and its superlative growth, having achieved the top ranking by major car manufacturers.</p>
<p>It was a natural step for Arshdeep, a purveyor of fine living, to expand the group’s operations into the hospitality sector with the acquisition of Fairfield by Marriott in Amritsar.</p>
<p>He always makes time for his fitness regime, enjoys reading and a game of golf in his leisure time. His passion for travelling and experiencing different cultures frequently takes him around the globe.</p>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
