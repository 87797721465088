import React, {useState} from 'react'
import banner from './image/lOGISTICS.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/jujhar-logistic.webp';
import icon1 from './image/logistic-01.webp';
import icon2 from './image/logistic-02.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import snapshot from './image/snapshot-logistic.webp';
import { NavLink } from 'react-router-dom';
import logo11 from './image/logo11.webp';
import logo16 from './image/logo16.webp';
import logo17 from './image/logo17.webp';
import logo18 from './image/logo18.webp';
import logo19 from './image/logo19.webp';
import logo20 from './image/logo20.webp';
import logo21 from './image/logo21.webp';
import logo22 from './image/logo22.webp';
import logo23 from './image/logo23.webp';
import logo24 from './image/logo24.webp';
import { Helmet } from 'react-helmet';

export const Logistics = () => {
    const [state, setState] = useState({var1:1, var2:1, var3:1, var4:1});
  return (
    <>
    <Helmet>
    <title>Jujhar Group Luxury Passenger Travel, Transport and Carrier Logistics | Jujhar Constructions & Travels</title>
      <meta name="description" content="Jujhar Constructions & Travels Private Limited, with its impeccable safety and travel services, has one of the largest and most luxurious fleets of Mercedes and Volvo buses."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner logistic' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                        <h2><span>Travel in <em>Luxury, Transport</em> with Precision</span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </section>
            <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>JUJHAR CONSTRUCTIONs & TRAVELS PVT. LTD.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                    <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} title='Jujhar Logistic' alt="Jujhar Logistic" width='200' height='200' />
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Jujhar Constructions & Travels Pvt. Ltd. </h1>
                                            <p>Offering luxury and premium travel experience and excellent car carrier logistics, Jujhar Constructions & Travels Private Limited is the flagship of Jujhar Group.</p>
                                            <p>With over 25,000 km of route permits, the company possesses one of the largest and most luxurious fleets of Mercedes and Volvo buses. It is the preferred mode of transport for smooth intra city travel, owing to high levels of customer satisfaction and an impeccable safety record.  </p>
                                            <p>The carrier logistics business is well equipped with advanced tracking and automated software and the company enjoys top rating by reputed vehicle manufactures like Maruti, Tata, Hyundai and Toyota. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute slow-move-anticlockwise'>
        <img src={dot_left} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="Passenger Transport – AC and Luxury Buses"  title='Passenger Transport – AC and Luxury Buses' width='70' height='70'/>
                                    <span>Passenger Transport – AC and Luxury Buses </span>
                                </div>   
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} alt="Logistics Provider – Car Transportation"  title='Logistics Provider – Car Transportation' width='70' height='70'/>
                                    <span>Logistics Provider – Car Transportation </span>
                                </div>                          
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>                           
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
                            <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var1===1) ? 1 : 1996} end={1996} duration={3} onEnd={()=> setState({var1:1996})}/> : <CountUp start={1} end={1996} duration={0} />}
        
        <div className='labelcont'>
          <em>Founded</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>
       
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var2===1) ? 1 : 132} end={132} duration={2} onEnd={()=> setState({var2:132})}/> : <CountUp start={1} end={132} duration={0} />}+      
             <p>Buses</p>          
           <div className='labelcont'>
             <em>Volvo, Mercedes
and Isuzu</em>
        </div>
            </div>            
          )}
        </VisibilitySensor>      
      </div>      
      
      
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var3===1) ? 1 : 375} end={375} duration={3} onEnd={()=> setState({var3:375})} /> : <CountUp start={1} end={375} duration={0} />} +
             <div className='labelcont'>
               <em>Car Carriers</em>
             </div>
            </div>
          )}
        </VisibilitySensor>
           
      </div>
      <div className="App">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="countnum">
             {isVisible ? <CountUp start={(state.var4===1) ? 1 : 650} end={650} duration={3} onEnd={()=> setState({var4:650})} /> : <CountUp start={1} end={650} duration={0}/>}+
             <div className='labelcont'>
          <em>Employees</em>
        </div>
            </div>
          )}
        </VisibilitySensor>
      
      </div>
                              
                            </div>
                            
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                    <li>Ranked No. 1 <span>in Car Transportation Segment</span></li>
                                    <li>Largest logistics <span>provider for Maruti</span></li>
                                    <li>State-of-the-art <span>workshops and fabrication<br /> facilities in Gurugram</span></li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='technology'>
                <div className='technology-partners'>
                    <div className='row'>
                        <div className='container'>
                            <div className='web-container'>
                            <div className='title'>
                                <h2>Technology Partners</h2>
                            </div>
                            <div className='partners-logo text-center'>
                            <ul data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                    <li><img src={logo16} alt="Maruti Suzuki" title="Maruti Suzuki" width='400' height='200'/></li>
                                    <li><img src={logo11} alt="TATA" title="TATA" width='400' height='200' /></li>
                                    <li><img src={logo17} alt="Toyota" title="Toyota" width='400' height='200' /></li>
                                    <li><img src={logo18} alt="Hyundai" title="Hyundai" width='400' height='200' /></li>
                                    <li><img src={logo19} alt="Mahindra" title="Mahindra" width='400' height='200' /></li>
                                    <li><img src={logo20} alt="Kia" title="Kia" width='400' height='200' /></li>
                                    <li><img src={logo21} alt="Volkswagen" title="Volkswagen" width='400' height='200' /></li>
                                    <li><img src={logo22} alt="Morris Garages" title="Morris Garages" width='400' height='200' /></li>
                                    <li><img src={logo23} alt="Nissan" title="Nissan" width='400' height='200' /></li>
                                    <li><img src={logo24} alt="Renault" title="Renault" width='400' height='200' /></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                                <a href="https://www.jujhargroup.com" class="btn-3" target='_blank' rel="noreferrer"><span>www.jujhargroup.com</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
