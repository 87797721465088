import React, {useState} from 'react';
import "aos/dist/aos.css";
import dots from "./image/icons.webp";
import cup from "./image/cup.webp";
import banner from './image/banner.webp';
import mbanner from './image/mbanner.webp';
import { Navbar } from "./Navbar";
import { Footer } from './Footer';
import dot_left from './image/icons.webp'
import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Helmet } from 'react-helmet';
import associate from './image/bg-layer.webp'
import logo1 from './image/Fastway.webp';
import logo2 from './image/Netplus_Broadband.webp';
import logo3 from './image/punjabi_hits.webp';
import logo4 from './image/Daily_Post_Punjabi.webp';
import logo5 from './image/Grand_Walk_Mall.webp';
import logo6 from './image/Living_India_News.webp';
import logo7 from './image/Sky_Ama_Infra.webp';
import logo8 from './image/Jujhar_Marriot_Logo.webp';
import logo9 from './image/Alpha.webp';
import logo10 from './image/jujhar-logistic.webp';
import logo11 from './image/jujhar-healthcare-black.webp';


const Home = () => {
  document.title = "Jujhar Group | Making People Smile. Since 1990."
  const [state, setState] = useState({turnover:1, workforce:1, states:1, cities:1, experience:1});
  return (
    <>
   <Helmet>
       <title>Jujhar: Empowering Excellence - A Journey with Jujhar Group</title>
       <meta name="description" content="Jujhar Group is a trailblazing organization empowering excellence in various sectors. Discover the inspiring journey of Jujhar Group."/>
       </Helmet>
          <Navbar/>
      <section id='video'>
        <div className='video position-relative'>
            <div className='videostn d-flex align-items-start justify-content-between'>
            <img src={banner} alt='Making people smile. Since 1990.' title='jujhar group' width='1779' height='932' className='desktop-show' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" data-aos-delay="250"/>
            <img src={mbanner} alt='Making people smile. Since 1990.' title='jujhar group' width='640' height='648' className='mobile-show' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" data-aos-delay="250"/>
            <img src={cup} className='slow-move-anticlockwise' title='jujhar group' alt='Jujhar group icon' width='159' height='129' />
            </div>
            <div id='dowwn'></div>
            <div className='videocont align-items-center justify-content-between'>                
              <h3 data-aos="flip-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" data-aos-delay="600"><span>Making people </span><span>smile. <em>Since 1990.</em> </span></h3>
             </div>
            <div className='down-arrow'>
            <Link to="dowwn" smooth={true} duration={50}>
                <span className='animated'></span>
                </Link>
            </div>            
        </div>
      </section>
      <section id='aboutus'>
          <div className='aboutus position-relative'>
              <div className='container'>
                <div className='web-container'>
        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
            <h2>About us</h2>
        </div>
        <div className='col-12 row'>
          <div className='col-md-6 col-xs-12'>
              <div className='heading desktop-show'  data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                <h1>
                <span className='col-12'>Jujhar Group - </span>
                <span className='col-12'>Over 34 years </span>
                <span className='col-12'>of business </span>
                <span className='col-12'><em>excellence</em></span></h1>
                </div>
                <div className='heading mobile-show' >
                <h1>Jujhar Group - Over 32 years of business <em>excellence</em></h1>
                </div>
          </div>
          
          <div className='col-md-6 col-xs-12' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
            <div className='aboutcont'>
              <p>The Jujhar Group is a multidimensional conglomerate headquartered in Ludhiana, Punjab. Headed by Chairman S Gurdeep Singh, a pioneer of the Indian Transport industry, the Jujhar Group has an enviable market leadership position in its many verticals and has an impressive customer base in growing sectors namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure, Hospitality & Healthcare.</p>
              <NavLink to='/about-jujhar-group' className="btn-3"><span>Read More</span></NavLink>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div className='dot-img-left position-absolute slow-move-anticlockwise'>
        <img src={dot_left}  alt='jujhar icon' title='jujhar group' width='542' height='525'/>
    </div>
    </div>
      </section>
      
      <section id='quote' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-delay="600">
        <div className='position-relative'>
          <div className='container'>
            <div className='row'>
              <div className='quote d-flex justify-content-center flex-wrap'>
                <div className='circles'>
                  <div className='bg-circle slow-move-anticlockwise'></div>
                  <div className='sm-circle slow-move-anticlockwise'></div>
                </div>
                <div className='dot-img position-absolute slow-move-anticlockwise'>
                    <img src={dots} alt='jujhar icon' title='jujhar group' width='542' height='525'/>
                </div>
                  <h2>“We have one mission in mind – to transform the lives of people in ways it has not been imagined yet.”</h2>
                  <h3 className='d-grid justify-content-center col-12 text-center data-aos-once="true"'>Mr. Arshdeep Singh Mundi<span className='col-12'>Executive Director</span></h3>
              </div>
          </div>
          </div>
        </div>
      </section>
      <section id='numbers'>
                 <div className="numbers">
                    <div className="container">
                        <div className="row">
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                        <h2>Jujhar Group in numbers</h2>
        </div>                           
                           
                        </div>
                    </div>
                       <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
                    <div className='numberbanner col-12'>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
                            {/* <div className="App">
            <div className="countnum">
             {isVisible ? <CountUp start={(state.turnover===1) ? 1 : 1200} end={1200} duration={3} onEnd= {()=> setState({turnover:1200}) }/> : <CountUp start={1} end={1200} duration={0} />}+
        <p>crore</p>   
        <div className='labelcont'>
          turnover
        </div>
            </div>     
            
      </div> */}
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 4500} end={4500} duration={2} onEnd={()=> setState({ workforce:4500 })} /> : <CountUp start={1} end={3000} duration={0} />} +                
             <div className='labelcont'>
          workforce
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.states===1) ? 1 : 8} end={8} duration={2} onEnd={()=> setState({ states:8 })} /> : <CountUp start={1} end={9} duration={0}/>} +     
             <div className='labelcont'>
          states
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.cities===1) ? 1 : 400} end={400} duration={3} onEnd={() => setState({cities:400})}/> : <CountUp start={1} end={400} duration={0} />} +
             <div className='labelcont'>
              Cities
             </div>
            </div>
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.experience===1) ? 1 : 30} end={30} duration={3} onEnd={()=> setState({setState:30})} /> : <CountUp start={1} end={30} duration={0} />} +
            <p>years</p>
             <div className='labelcont'>
              Experience
             </div>
            </div>     
            
      </div>
      
      </div>   
      <div className='whitebtn d-flex justify-content-center'>
      <a href='https://www.jujhargroup.com/about-jujhar-group#numbers' smooth={true} duration={50} className="btn-3"><span>View All</span></a>
      </div>
      </div>    
        
      </div>
                        </div>
                        
            )}
        </VisibilitySensor>
        
        </div></section>
      <div className='business-associates'>
        <div className='container'>
          <div className='row'>
            <div className='mid-container'>
            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
              <h2>Our Businesses </h2>
            </div>
            <div className='quote text-center'>
            <p data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">Our companies and investments span across various business verticals and have presence in several geographies.</p>
            </div>
            <div className='businessassoc'>
              <img src={associate} className='network desktop-show' alt="Jujhar Group Network" title='Jujhar Group' width='1364' height='680' />     
                <span className='ycircle circle01'></span>
                <span className='ycircle circle02'></span>
                <span className='ycircle circle03'></span>
                <span className='ycircle circle04'></span>
                <span className='ycircle circle05'></span>
                <span className='ycircle circle06'></span>
                <span className='ycircle circle07'></span>
                <span className='ycircle circle08'></span>
              <div className='bg-layer'>
                <div className='bglayerbox'  data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                    <img src={logo1} className='img02' alt="Fastway Transmissions" title='Fastway Transmissions' width='200' height='200' />
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="800" data-aos-once="true">
                    <img src={logo2} className='img02' alt="Netplus Broadband" title='Netplus Broadband' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="1200" data-aos-once="true">
                    <img src={logo3} className='img02' alt="Punjabi Hits" title='Punjabi Hits' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="1400" data-aos-once="true">                    
                    <img src={logo4} className='img02' alt="Daily Post" title='Daily Post' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="1600" data-aos-once="true">                    
                    <img src={logo5} className='img02' alt="Grand Walk" title='Grand Walk'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="1800" data-aos-once="true">                    
                    <img src={logo6} className='img02' alt="Living Media" title='Living Media' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="2000" data-aos-once="true">                    
                    <img src={logo7} className='img02' alt="Sky Ama Infra" title='Sky Ama Infra' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="2200" data-aos-once="true">                    
                    <img src={logo8} className='img02' alt="Fairfield by Marriott" title='Fairfield by Marriott' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="2200" data-aos-once="true">                    
                    <img src={logo9} className='img02'  alt='Alpha International City' title='Alpha International City' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="2200" data-aos-once="true">                    
                    <img src={logo10} className='img02' alt='Jujhar Construction & Travels' title='Jujhar Construction & Travels' width='200' height='200'/>
                </div>
                <div className='bglayerbox' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="2200" data-aos-once="true">                    
                    <img src={logo11} className='img02' alt='Jujhar Healthcare' title='Jujhar Healthcare' width='200' height='200'/>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aligncenter"><a href='https://www.jujhargroup.com/businesses#our-business' className="btn-3"><span>Know more</span></a></div>

      </div>
     <Footer/>
    </>
  );
};

export default Home;
