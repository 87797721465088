import React from "react";
import Error404 from './Error404'
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

const Error = () => {
  return (
    <>
    <Navbar/>
      <Error404 />
      <Footer/>
    </>
  );
};

export default Error;
