import React from 'react'
import banner from './image/csr-banner.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import dots from "./image/icons.webp";
import { Helmet } from 'react-helmet';
import csr01 from './image/csr01.webp'
import csr02 from './image/csr02.webp'
import csr03 from './image/csr03.webp'
import csr04 from './image/csr04.webp'
import sport01 from './image/sport01.webp'
import sport02 from './image/sport02.webp'
import sport03 from './image/sport03.webp'
import sport04 from './image/sport04.webp'
import csrpdf from './image/Jujhar-CSR.pdf'

export const Csr = () => {
    document.title="Corporate Social Responsibility - Jujhar Group Ludhiana Punjab"
  return (
    <>
    <Helmet>
    <title>Corporate Social Responsibility - Jujhar Group Ludhiana Punjab</title>
      <meta name="description" content="Social wellbeing and collective good is an integral part of the Jujhar Group. The Group endeavors for sustainable wellbeing of the stakeholder communities by addressing economic, environmental and social issues."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont'>
                        <h2><span>Making people smile.<em> Since 1990.</em></span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section id='csr'>
                <div className='csr position-relative'>
                <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />  
                </div>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='row'>
                            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                                <h1>Our Csr</h1>
                            </div>
                            <div className='csrcont text-center' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                <p>Social wellbeing and collective good is an integral part of the Jujhar Group. The Group endeavors for sustainable wellbeing of the stakeholder communities by addressing economic, environmental and social issues.</p>
                               
                               <div className='csrimg'>
                               <ul>
                                   <li><img src={csr01} alt="WATER CONSERVATION" title='WATER CONSERVATION' width='283' height='244'/><span>WATER CONSERVATION</span></li>
                                   <li><img src={csr02} alt="LIVELIHOOD ENHANCEMENT" title='LIVELIHOOD ENHANCEMENT' width='283' height='244' /><span>LIVELIHOOD ENHANCEMENT</span></li>
                                   <li><img src={csr03} alt="HEALTH" title='HEALTH' width='283' height='244'  /><span>HEALTH</span></li>
                                   <li><img src={csr04} alt="EDUCATION" title='EDUCATION' width='283' height='244'  /><span>EDUCATION</span></li>
                               </ul>
                               </div>
                               <h4>PROMOTION OF RURAL SPORTS </h4>
                               <ul className='pomotionimg'>
                                   <li><img src={sport01} alt="SPORTS" title='SPORTS' width='283' height='244' /></li>
                                   <li><img src={sport02} alt="SPORTS" title='SPORTS' width='283' height='244' /></li>
                                   <li><img src={sport03} alt="SPORTS" title='SPORTS' width='283' height='244' /></li>
                                   <li><img src={sport04} alt="SPORTS" title='SPORTS' width='283' height='244' /></li>
                               </ul>
                               <p>Jujhar Group is committed to promote sports at the grassroots level to raise the bar of the sports persons across Punjab. It regularly upports and sponsors kabaddi players and tournaments to increase its popularity and even has a television channel dedicated to it.</p>
                               
                               </div>
                        </div>
                        </div>
                    </div>
                </div>
        </section>
        <section id='businessbtn' className='p-0'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">                        
                             <a href={csrpdf} class="btn-3" target='_blank' rel="noreferrer"><span>Our CSR Policy</span></a>                             
                        </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
