import React from 'react'
import banner from './image/Infrastructure.webp';
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import logo1 from './image/Sky_Ama_Infra.webp';
import icon1 from './image/engineers.webp';
import icon2 from './image/fiber.webp';
import dots from "./image/icons.webp";
import dot_left from './image/icons.webp'
import { NavLink } from 'react-router-dom';
import snapshot from './image/snapshot-infrastructure.webp';
import { Helmet } from 'react-helmet';

export const Infrastructure = () => {
  return (
    <>
    <Helmet>
    <title>Fiber Laying Company - Sky Ama Infra Pvt. Ltd. | Jujhar Group</title>
      <meta name="description" content="Jujhar Group - Sky Ama Infra Pvt. Ltd. is a fiber laying company that specializes in the technical and managerial capabilities required to execute infrastructure projects for telephone, media and internet companies."/>
    </Helmet>
        <Navbar/>
        <section id='banner' className='p-0 textcenter'>
        <div className='banner infrastructure' style={{ backgroundImage: `url(${banner})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='bannercont pulll-center'>
                    <h2><span>Redefining <em>Industrial Norms</em> at Scale</span></h2>
                        <div className='backbtns'>
                        <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Go Back</span></NavLink>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/businesses'>Our BUSINESSES</NavLink></li>
                                <li>SKY AMA INFRA PVT LTD</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <section id='entertainement'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='dot-img position-absolute slow-move-anticlockwise'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <img src={dots} title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />  
                </div>
                            <div className='overview'>
                                <div className='row'>
                                    <div className='col-md-4 col-xs-12'>
                                        <div className='entertainmentlogo'>
                                    <img src={logo1} alt='Sai Infra' title='Sai Infra' width="200" height="200"/>
                                    </div>
                                    </div>
                                    <div className='col-md-8 col-xs-12'>
                                        <div className='entertainmentdtl'>
                                            <h1>Sky AMA Infra Pvt Ltd</h1>
                                            <p>Sky Ama Infra Pvt. Ltd. is an engineering and system integration contract execution company that specializes in technical and managerial capabilities required to execute infrastructure projects for telephone, media and internet companies. </p>
                                            <p>It enjoys an impressive portfolio of challenging, large scale projects - having successfully executed the laying of more than 6000 km of Optic Fiber Cable for Fastway Transmissions and other allied companies. Sky Ama Infra is the first company of its kind in the private sector, redefining industrial norms by having built an OFC network on par with major national companies at much lower costs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='service'>
                <div className='service'>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='dot-img-left position-absolute slow-move-anticlockwise'>
        <img src={dot_left}  title='Jujhar Group' alt="Jujhar Group" width='542' height='525' />  
    </div>
                        <div className='row'>
                            <div className='title'>
                                <h2>Services</h2>
                            </div>
                            <div className='col-12 row justify-content-center'>
                            <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon1} alt="Engineering & System Integration" title='Engineering & System Integration' width='70' height='70'/>
                                    <span>Engineering & System Integration </span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <img src={icon2} alt="Laid Fiber Service" title='Laid Fiber Service' width='70' height='70'/>
                                    <span>Laid Fiber Service </span>
                                </div>                             
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section id='numbers'>
                <div className="numbers snapshot">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2>Snapshot</h2>
        </div>
                            
                           
                        </div>
                    </div>
                    <div className='numberbanner col-12' style={{ backgroundImage: `url(${snapshot})` }}>
                      <div className='container'>
                        <div className='web-container'>
                            <div className='highlightpoint'>
                                <ul className='d-flex justify-content-center'>
                                <li><span>First company of its kind</span> in the private sector</li>
                                <li><span>Specialization in execution of</span>large scale fiber laying projects</li>
                                <li><span>Launched Wi-Fi services at</span> the Golden Temple<br/>in association with Amritsar Smart City</li>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section id='businessbtn'>
              <div className='businessbtn'>
                <div className='container'>
                  <div className='web'>
                  <div class="backbtn text-center">  
                            <NavLink className="btn-3" to='/businesses'><span><i className='backarrow'></i>Jujhar Businessess</span></NavLink>                          
                            <a href="http://www.skyama.co.in" class="btn-3" target='_blank' rel="noreferrer"><span>www.skyama.co.in</span></a>
                                </div>
                  </div>
                </div>
              </div>
            </section>
        <Footer/>
    </>
  )
}
