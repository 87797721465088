import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Disclaimer = () => {
  return (
    <>
        <Navbar/>
        <section id='content'>
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                                <div className='title'>
                                    <h2>Disclaimer</h2>
                                </div>
                                <p>The Jujhar Group reserves copyright on all text, contents, graphics and trademarks displayed on this site, which are owned by/belong by way of means to the Jujhar Group. The information on this site is for general viewing only, and should not be relied upon for any specific purpose by the user. The user shall also abstain from the distribution of the text or graphics to any third parties without the express written consent of the group. The user shall also not, without the group’s prior permission, copy and distribute this information on any other server, or modify or reuse text or graphics on this or any other system.</p>
                                <p>Although the Jujhar Group endeavors to make certain that all information and recommendations provided as part of this website are correct at the time of inclusion, the group does not confirm the accuracy of such information. The Jujhar Group makes no representations or affirmations as to the completeness or accuracy of the information. Additionally, this site contains certain links to other websites maintained by third parties that may or may not operate under the jurisdiction or control of the group; as such the Jujhar Group makes no representations as to the accuracy or any other aspect of information contained in such other websites.</p>
                                <p>In no event will the Jujhar Group, its related partnerships or corporations, or the partners, agents or employees thereof be liable for any decision made by the user and/or site visitor for any inference or action taken in reliance on the information provided in this site or for any consequential, special or similar damages. Applicable Law and Jurisdiction of this Disclaimer are governed by and to be interpreted in accordance with the laws of India, without regard to the choice or conflicts of law provisions of any jurisdiction. The user/site visitor agrees that in the event of any dispute arising in relation to this Disclaimer or any dispute arising in relation to the web site whether in contract or tort or otherwise, to submit to the jurisdiction of the courts located in Punjab (India) only for the resolution of all such disputes.</p>
                                <p>Any and all forward-looking statements on the website involve a number of risks, uncertainties and other factors that could cause actual results to differ materially from those suggested by the forward-looking statements. These risks and uncertainties include, but are not limited to the successful implementation of our corporate strategies, our growth and expansion plans, the obtainment of regulatory approvals, our provisional policies, technological developments, investments and business income, cash flows, our exposure to the market risks as well as other allied and unforeseen risks. The company does not undertake any obligation to update forward-looking statements to reflect events or circumstances after the date thereof.</p>
                        </div>
                    </div>
                </div>
            </section>    
        <Footer/>
    </>
  )
}
