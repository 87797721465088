import React from 'react'
import { NavLink } from 'react-router-dom'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Helmet } from 'react-helmet'
import leadership01 from './image/harleen-kaur.webp';
import leadership10 from './image/Sanjay-Goyal.png';

export const Leadershipdetails10 = () => {
  return (
    <>
    <Helmet>
    <title>Mr. Sanjay Goyal | Group CFO and Head (System & Process)</title>
      <meta name="description" content="Mr Goyal is A Visionary, Finance and Business leader having 26+ years of diversified experience in defining/implementing financial strategy, using technology and optimizing operational excellence across diverse industries."/>
    </Helmet>
        <Navbar />
        <section id='breadcrum' className='p-0'>
            <div className='breadcrum'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>                             
                            <ul className='d-flex'>
                                <li><NavLink to='/about-us'>About</NavLink></li>
                                <li><NavLink to='/leadership'>Leadership</NavLink></li>
                                <li>Mr. Sanjay Goyal</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='leadershipdetails'>
                <div className='leadershipdetails'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <div className='leaddetails'>
                                <h1>Mr. Sanjay Goyal</h1>
                                <h2><span>Group CFO and Head (System & Process)</span></h2>
                                <div className='leaddetail'>
                                <img src={leadership10} alt='Mr. Sanjay Goyal' title='Mr. Sanjay Goyal' width='200' height='200'/>
                                <p><b>"To lead the industry in delivering seamless experiences, driving innovation and growth while ensuring exceptional value for our customers and stakeholders"</b></p>
                                <p>Mr Goyal is A Visionary, Finance and Business leader having 26+ years of diversified experience in defining/implementing financial strategy, using technology and optimizing operational excellence across diverse industries.</p>
                                <p>A strategic QQCT process Driver and influencer of new ideas and execution, focusing on vision in alignment with benefits to all stakeholders. Involved in top management assignments including Board Reporting Roles equipped with technology (4G, Fixed line data & IPTV) sector, Media & Digital Content distribution services together with diverse experience in Retail, FMCG, Hospitality, Real Estate, SEZ, Construction and Engineering Industry with Reliance JIO, Siti Networks Limited (Essel Group), Vishal Retail Limited and Dharampal Satyapal Group, as a part of his professional credentials.</p>
                                <p>Mr Goyal exhibited the ability to translate the vision into reality, lead various teams, bring techno-innovative solutions in processes and improve the top & bottom line including shareholder’s wealth, with shared inputs and implementation strategies. He has also represented the ASSOCHAM and PHD Commerce as Tax Advisory Committee member and key Instrumental in interacting with PE Firms, Banking Institutions for Equity Incl Private Equity & QIP, and Debt syndication during his professional stint.</p>
                                <NavLink className="btn-3" to='/leadership'><span><i className='backarrow'></i>Jujhar Leadership</span></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </section>
        <Footer />
    </>
  )
}
